import { useOTADeviceInformationQuery } from 'gql/generated/graphql'
import useWrappedQuery from 'hooks/useWrappedQuery'

const useOTAInformation = (module_id: string) =>
  useWrappedQuery(
    useOTADeviceInformationQuery({ variables: { module_id }, pollInterval: 0 }),
    data => data?.ota_device_information,
    false,
  )

export default useOTAInformation
