import { FrameType } from '__generated__/globalTypes'
import { SEGWAY_SCHEMES } from 'constants/domain'
import { ModuleHardware } from 'gql/generated/graphql'

// This module provides utility functions for treating Segways as e-Bikes rather than Scooters

export type GetDerivedHardwareType = {
  hardware_type?: ModuleHardware | null
  vehicle?: {
    frame_type?: string | null
  } | null
}

export const getDerivedHardwareType = <T extends GetDerivedHardwareType>(
  module?: T | null,
): ModuleHardware => {
  const hardwareType =
    module?.vehicle?.frame_type === FrameType.segway_e_bike
      ? ModuleHardware.bbe
      : module?.hardware_type

  return hardwareType ?? ModuleHardware.beryl_bike
}

export const getDerivedModule = <T extends GetDerivedHardwareType>(
  module?: T | null,
): T =>
  ({
    ...module,
    hardware_type: getDerivedHardwareType(module),
  } as T)

export type GetDerivedZone = {
  ebikes: number
  scooters: number
} | null

export const getDerivedZone = <T extends GetDerivedZone>(
  zone?: T,
  scheme_id?: string | null,
): T => {
  if (!zone) return null as T
  const isSegwayScheme = (SEGWAY_SCHEMES as string[]).includes(
    scheme_id as string,
  )
  return {
    ...zone,
    // NOTE: We generate these numbers based on hardware_type,
    // so in a Segway scheme, scooters should count as e-bikes.
    // This WILL NOT WORK if we commission scooters and Segways
    // to the same scheme.
    ebikes: isSegwayScheme ? zone.ebikes + zone.scooters : zone.ebikes,
    scooters: isSegwayScheme ? 0 : zone.scooters,
  }
}
