import { Flex, Icon as BerylIcon, Box } from '@weareberyl/design-system'

import type { UsersListQuery_table_UserConnection_nodes_User } from 'gql/generated/graphql'
import type { ICustomColumnProps } from 'components/Table'
import UserLink from 'components/UserLink'
import Roles from 'components/User/Roles'
import Copy from 'components/Copy'
import { Dropdown } from 'antd'
import { Actions } from '../actions'

const columns: ICustomColumnProps<UsersListQuery_table_UserConnection_nodes_User>[] =
  [
    {
      title: 'ID',
      dataIndex: 'id',
      sorter: true,
      sortFunction: (direction: string) => ({
        id: direction,
      }),
      render: (id, user) => (
        <Flex pr={3}>
          <Copy id={id} text={id} showText={false} />
          <Box flex={1}>
            <UserLink user={user}>
              <code style={{ whiteSpace: 'nowrap' }}>{user.id}</code>
            </UserLink>
          </Box>
        </Flex>
      ),
    },
    {
      title: 'Email',
      sorter: true,
      sortFunction: (direction: string) => ({
        email: direction,
      }),
      render: user => <UserLink user={user}>{user.email}</UserLink>,
    },
    {
      title: 'Valid licence',
      dataIndex: 'has_valid_licence',
      align: 'center',
      render: valid => (
        <Box
          bg={valid ? 'mint' : 'prawn'}
          width={20}
          height={20}
          borderRadius={10}
          justifyContent="center"
          alignItems="center"
          margin="0 auto"
        >
          <BerylIcon
            type={valid ? 'tick' : 'cross'}
            color={valid ? 'jade' : 'brick'}
            width={10}
            height={10}
          />
        </Box>
      ),
    },
    {
      title: 'Active',
      dataIndex: 'is_blocked',
      align: 'center',
      render: value => (
        <Box
          bg={value ? 'prawn' : 'mint'}
          width={20}
          height={20}
          borderRadius={10}
          justifyContent="center"
          alignItems="center"
          margin="0 auto"
        >
          <BerylIcon
            type={value ? 'cross' : 'tick'}
            color={value ? 'brick' : 'jade'}
            width={10}
            height={10}
          />
        </Box>
      ),
    },
    {
      title: 'Roles',
      dataIndex: 'roles',
      render: roles => (
        <Flex>
          <Roles labelVariant="micro" roles={roles || []} closable={false} />
        </Flex>
      ),
    },
    {
      title: 'Actions',
      className: 'xs-hide',
      align: 'center',
      render: ({ id }) => (
        <Dropdown trigger={['click']} overlay={<Actions user_id={id} />}>
          <a href="#na" className="ant-dropdown-link">
            ...
          </a>
        </Dropdown>
      ),
    },
  ]

export default columns
