import { Alert, message } from 'antd'
import { Card as BerylCard, Heading } from '@weareberyl/design-system'
import { useHistory } from 'react-router-dom'
import { startCase } from 'lodash'

import * as routes from 'constants/routes'
import HeadTitle from 'components/HeadTitle'
import { useCreatePromotion } from '../data'
import { type CreateEoJPromotionMutationVariables } from 'gql/generated/graphql'
import CombinedPromotionForm from '../form/CombinedForm'

const PromotionCreate = () => {
  const history = useHistory()
  const { createPromotion, saving, error } = useCreatePromotion()

  const onFinish = async (variables: CreateEoJPromotionMutationVariables) => {
    try {
      await createPromotion({ variables })
    } catch {
      // Error returned by API will be displayed by the hook value
      // to be displayed in the Alert component below
      message.error("Couldn't create promotion")
      return
    }

    message.success('Campaign created')
    history.replace(routes.CAMPAIGNS)
  }

  return (
    <>
      <HeadTitle pageTitle="Create promotion" />

      <BerylCard mb={4} p={5} flex={1}>
        <Heading variant="callout">Create an Auto-promotion</Heading>
      </BerylCard>

      {error && (
        <Alert
          type="error"
          message="Oh dear"
          description={startCase(error.message)}
          style={{ marginBottom: '20px' }}
          showIcon
        />
      )}

      <CombinedPromotionForm onFinish={onFinish} saving={saving} />
    </>
  )
}

export default PromotionCreate
