import { Card } from 'antd'
import { useState } from 'react'
import LockStateHistory from './LockStateHistory'
import DockHistory from './DockHistory'
import ConnectivityHistory from './ConnectivityHistory'
import VehicleStateEvents from 'components/Vehicle/VehicleStateEvents'

interface IModuleEvents {
  id: string
  isMaptexVehicle?: boolean
}

const ModuleEvents = ({ id, isMaptexVehicle = false }: IModuleEvents) => {
  const [currentTab, setCurrentTab] = useState('vehicleEvent')
  const tabs = [
    {
      key: 'vehicleEvent',
      tab: 'Vehicle State',
    },
    {
      key: 'lockState',
      tab: 'Lock State',
    },
    ...(isMaptexVehicle
      ? []
      : [
          {
            key: 'dock',
            tab: 'Dock State',
          },
          {
            key: 'connectivity',
            tab: 'Connectivity',
          },
        ]),
  ]
  const content = {
    vehicleEvent: <VehicleStateEvents module_id={id} />,
    lockState: <LockStateHistory id={id} />,
    dock: <DockHistory id={id} />,
    connectivity: <ConnectivityHistory id={id} />,
  }

  return (
    <Card
      activeTabKey={currentTab}
      onTabChange={t => setCurrentTab(t)}
      tabList={tabs}
      style={{ fontFamily: 'Hellix-SemiBold' }}
      tabProps={{ tabBarStyle: { display: 'flex' } }}
    >
      {content[currentTab]}
    </Card>
  )
}

export default ModuleEvents
