import { PropsWithChildren } from 'react'
import { Link } from 'react-router-dom'

import * as routes from 'constants/routes'

import { useZoneNameFragment } from 'gql/generated/graphql'

type ZoneLinkProps = PropsWithChildren<{
  id: string
}>

export default ({ id, children }: ZoneLinkProps) => {
  const zonePath = `${routes.ZONES}/${id}`

  const { data, complete } = useZoneNameFragment({ id })

  if (!complete || !data || !data.scheme) {
    return <span>Zone: {id}</span>
  }

  const schemeZonePath = routes.prependScheme(zonePath, data.scheme.id)

  return <Link to={schemeZonePath}>{children || data.name}</Link>
}
