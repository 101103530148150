import { useEffect } from 'react'
import { QueryResult } from '@apollo/client'

import { debouncedMessage } from 'utils/debouncedMessage'

const useWrappedQuery = <T, K>(
  { data, error, loading, refetch }: QueryResult<T>,
  getOutput: (data?: T) => K | undefined,
  showErrors = true,
) => {
  useEffect(() => {
    if (error && showErrors) {
      debouncedMessage.error(error.message)
    }
  }, [error, showErrors])

  if (loading || !getOutput(data)) {
    return { refetch, error, loading }
  }

  return { data: getOutput(data), refetch, error, loading }
}

export default useWrappedQuery
