import { useCompatibleOTAFirmwaresQuery } from 'gql/generated/graphql'
import useWrappedQuery from 'hooks/useWrappedQuery'

const useCompatibleOTAFirmwares = (module_id: string) =>
  useWrappedQuery(
    useCompatibleOTAFirmwaresQuery({
      variables: { module_id },
      pollInterval: 0,
    }),
    data => data?.compatible_ota_firmwares,
  )

export default useCompatibleOTAFirmwares
