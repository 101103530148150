import { Select } from 'antd'
import { ApolloQueryResult } from '@apollo/client'

import Query from 'components/Query'
import { AllVersionsDocument, AllVersionsQuery } from 'gql/generated/graphql'
import { isNotNullOrUndefined } from 'utils/types'

const Option = Select.Option

const SelectVersion = ({ onChange }) => {
  return (
    <Query waitFor="data.firmware" query={AllVersionsDocument} pollInterval={0}>
      {({ data }: ApolloQueryResult<AllVersionsQuery>) => {
        const versions = data.firmware.filter(isNotNullOrUndefined)
        return (
          <Select
            placeholder="Select a version"
            style={{ width: '100%' }}
            onChange={onChange}
          >
            {versions.map(version => {
              const supportedHardware = version?.supported_hardware ?? []
              const supportedFrametypes = version?.supported_frame_types ?? []
              const allTags = [...supportedHardware, ...supportedFrametypes]

              const optionRightText =
                allTags.length !== 0 ? `[ ${allTags.join(', ')} ]` : 'Not set'

              return (
                <Option key={version.id} value={version.name}>
                  <div
                    style={{ display: 'flex', justifyContent: 'space-between' }}
                  >
                    <div>{version.name}</div>
                    <div>{optionRightText}</div>
                  </div>
                </Option>
              )
            })}
          </Select>
        )
      }}
    </Query>
  )
}

export default SelectVersion
