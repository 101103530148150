import { Row, Col, Card } from 'antd'

import Map from './Map'
import Summary from './Summary'
import useModuleVehicle from 'hooks/useModuleVehicle'
import HeadTitle from 'components/HeadTitle'
import { FrameType } from '__generated__/globalTypes'
import ModuleDetails from './Details'
import ModuleEvents from './Events'
import JourneysAndJobs from 'components/Module/JourneysAndJobs'

type ModuleProps = {
  id: string
}

const Module = ({ id }: ModuleProps) => {
  const vehicle = useModuleVehicle(id)
  const scheme = vehicle?.detail?.scheme
  const assetTag = vehicle?.frame_id
  const isMaptexVehicle = [
    FrameType.okai_scooter,
    FrameType.segway_e_bike,
  ].includes(vehicle?.frame_type as FrameType)

  return (
    <>
      <HeadTitle pageTitle={`Module ${id}`} />
      <Row gutter={16} className="fill-height">
        <Col xs={24} lg={10} className="flex-col">
          <Summary id={id} />
        </Col>
        <Col xs={24} lg={14} style={{ minHeight: 500 }}>
          <Card
            id="module-map-card"
            bodyStyle={{
              padding: 0,
            }}
            style={{ containerType: 'size' }}
          >
            <div className="minimap-wrapper-module">
              <Map id={id} />
            </div>
          </Card>
        </Col>
        <Col md={24} xl={13}>
          <ModuleEvents id={id} isMaptexVehicle={isMaptexVehicle} />
        </Col>
        <Col md={24} xl={11}>
          <ModuleDetails id={id} isMaptexVehicle={isMaptexVehicle} />
        </Col>
        <Col xs={24} lg={24}>
          <JourneysAndJobs id={id} assetTag={assetTag} scheme={scheme} />
        </Col>
      </Row>
    </>
  )
}

export default Module
