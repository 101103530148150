/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

import { JobStatusEnum } from 'gql/generated/graphql'

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum AssistConfig {
  ASSIST_CONFIG_DISABLE = 'ASSIST_CONFIG_DISABLE',
  ASSIST_CONFIG_NORMAL = 'ASSIST_CONFIG_NORMAL',
  ASSIST_CONFIG_OVERRIDE = 'ASSIST_CONFIG_OVERRIDE',
}

/**
 * Different availability states of the device
 */
export enum AvailabilityStatus {
  available = 'available',
  unavailable = 'unavailable',
}

export enum BatteryGroup {
  A200P = 'A200P',
  BBE1 = 'BBE1',
  BBE2 = 'BBE2',
  ECARGO = 'ECARGO',
  ES400A = 'ES400A',
  ES400B = 'ES400B',
  ES410 = 'ES410',
}

export enum CardBrandType {
  amex = 'amex',
  diners = 'diners',
  diners_club = 'diners_club',
  discover = 'discover',
  jcb = 'jcb',
  mastercard = 'mastercard',
  unionpay = 'unionpay',
  unknown = 'unknown',
  visa = 'visa',
}

export enum CardType {
  apple_pay = 'apple_pay',
  card = 'card',
  google_pay = 'google_pay',
}

export enum ChargeType {
  cancel_journey_penalty = 'cancel_journey_penalty',
  minute_refund = 'minute_refund',
  minute_topup = 'minute_topup',
  out_of_service_area_charge = 'out_of_service_area_charge',
  out_of_zone_charge = 'out_of_zone_charge',
  partially_locked_in_zone_parking_charge = 'partially_locked_in_zone_parking_charge',
  product_purchase = 'product_purchase',
  tax = 'tax',
  time_based_charge = 'time_based_charge',
  time_based_minute_charge = 'time_based_minute_charge',
  time_based_overflow_charge = 'time_based_overflow_charge',
  time_based_period_pass_charge = 'time_based_period_pass_charge',
  unlock_fee = 'unlock_fee',
  vehicle_not_permitted_in_zone_charge = 'vehicle_not_permitted_in_zone_charge',
  zone_max_capacity_charge = 'zone_max_capacity_charge',
}

export enum CurrencyPaymentProviderType {
  stripe_customer = 'stripe_customer',
  swift_customer = 'swift_customer',
  trafi_customer = 'trafi_customer',
}

export enum DeploymentEventState {
  downloading = 'downloading',
  installed = 'installed',
  installing = 'installing',
  pending = 'pending',
  starting = 'starting',
}

export enum Direction {
  asc = 'asc',
  desc = 'desc',
}

export enum FrameType {
  beryl_bike = 'beryl_bike',
  beryl_e_bike = 'beryl_e_bike',
  beryl_e_bike_2 = 'beryl_e_bike_2',
  okai_scooter = 'okai_scooter',
  pashley_tfl = 'pashley_tfl',
  pashley_westmidlands_bike = 'pashley_westmidlands_bike',
  pashley_westmidlands_e_bike = 'pashley_westmidlands_e_bike',
  raleigh_cargo_e_bike = 'raleigh_cargo_e_bike',
  segway_e_bike = 'segway_e_bike',
}

export enum HiringCapacityStatus {
  available_bikes = 'available_bikes',
  limited_bikes = 'limited_bikes',
  no_bikes = 'no_bikes',
}

export enum InvoiceStatus {
  fully_refunded = 'fully_refunded',
  outstanding = 'outstanding',
  partially_refunded = 'partially_refunded',
  pending = 'pending',
  settled = 'settled',
  void = 'void',
}

export enum JobAutomationSideEffect {
  set_unavailable = 'set_unavailable',
}

export enum JourneyInitiator {
  app = 'app',
  sms = 'sms',
  trafi = 'trafi',
}

export enum LockConfiguration {
  electronic_lock = 'electronic_lock',
  front_and_electronic_rear_lock = 'front_and_electronic_rear_lock',
  front_and_rear_lock = 'front_and_rear_lock',
  no_lock = 'no_lock',
  rear_lock = 'rear_lock',
}

export enum LockState {
  lock_failed = 'lock_failed',
  lock_fault = 'lock_fault',
  lock_stuck = 'lock_stuck',
  lock_timeout = 'lock_timeout',
  locked = 'locked',
  not_applicable = 'not_applicable',
  spontaneous_lock = 'spontaneous_lock',
  spontaneous_unlock = 'spontaneous_unlock',
  unknown = 'unknown',
  unlock_failed = 'unlock_failed',
  unlock_timeout = 'unlock_timeout',
  unlocked = 'unlocked',
  waiting_for_lock = 'waiting_for_lock',
  waiting_for_unlock = 'waiting_for_unlock',
}

export enum ModuleHardware {
  bbe = 'bbe',
  beryl_bike = 'beryl_bike',
  scooter = 'scooter',
  smartbike_tfl = 'smartbike_tfl',
  bils_u = 'bils_u',
}

export enum ModuleHardwareType {
  BBE = 'BBE',
  BERYL_BIKE = 'BERYL_BIKE',
  SMARTBIKE_TFL = 'SMARTBIKE_TFL',
  UNDEFINED_HW = 'UNDEFINED_HW',
  UNKNOWN_HW = 'UNKNOWN_HW',
}

/**
 * List of acceptable tag names
 */
export enum ModuleTagName {
  event = 'event',
  low_battery_override = 'low_battery_override',
  maintenance = 'maintenance',
  missing = 'missing',
  workshop = 'workshop',
  vandalism = 'vandalism',
}

export enum ParkingCapacityStatus {
  available_space = 'available_space',
  limited_space = 'limited_space',
  no_space = 'no_space',
}

export enum ParkingStatus {
  out_of_service_area = 'out_of_service_area',
  out_of_zone = 'out_of_zone',
  valid = 'valid',
  vehicle_not_permitted = 'vehicle_not_permitted',
  zone_max_capacity = 'zone_max_capacity',
}

export enum PaymentProviderType {
  minute_customer = 'minute_customer',
  stripe_customer = 'stripe_customer',
  swift_customer = 'swift_customer',
  trafi_customer = 'trafi_customer',
}

/**
 * Used by clients to group similar products in the UI
 */
export enum ProductType {
  bundle = 'bundle',
  day_pass = 'day_pass',
  payg = 'payg',
  period_pass = 'period_pass',
}

export enum ServiceAreaType {
  go_slow = 'go_slow',
  no_go = 'no_go',
  normal = 'normal',
  segment = 'segment',
}

export enum TaskCodeReason {
  other = 'other',
  vandalism = 'vandalism',
}

export enum TaskReplacementReusability {
  non_reusable = 'non_reusable',
  reusable_with_repair = 'reusable_with_repair',
  reusable_without_repair = 'reusable_without_repair',
}

export enum TaskStatusEnum {
  blocked = 'blocked',
  complete = 'complete',
  new = 'new',
}

export enum TransactionCategory {
  discount = 'discount',
  generic = 'generic',
  refund = 'refund',
  reversal = 'reversal',
  settler = 'settler',
  waiver = 'waiver',
}

export enum TransactionMetaChargeType {
  cancel_journey_penalty = 'cancel_journey_penalty',
  minute_refund = 'minute_refund',
  minute_topup = 'minute_topup',
  out_of_service_area_charge = 'out_of_service_area_charge',
  out_of_zone_charge = 'out_of_zone_charge',
  partially_locked_in_zone_parking_charge = 'partially_locked_in_zone_parking_charge',
  product_purchase = 'product_purchase',
  tax = 'tax',
  time_based_charge = 'time_based_charge',
  time_based_minute_charge = 'time_based_minute_charge',
  time_based_overflow_charge = 'time_based_overflow_charge',
  time_based_period_pass_charge = 'time_based_period_pass_charge',
  undocked_in_zone_parking_charge = 'undocked_in_zone_parking_charge',
  unlock_fee = 'unlock_fee',
  vehicle_not_permitted_in_zone_charge = 'vehicle_not_permitted_in_zone_charge',
  zone_max_capacity_charge = 'zone_max_capacity_charge',
}

export enum TransactionResultType {
  failed = 'failed',
  pending = 'pending',
  succeeded = 'succeeded',
}

export enum TransactionType {
  currency_transaction = 'currency_transaction',
  minute_transaction = 'minute_transaction',
}

export enum VehicleLockState {
  front_lock_only = 'front_lock_only',
  lock_fault = 'lock_fault',
  locked = 'locked',
  rear_lock_only = 'rear_lock_only',
  unknown = 'unknown',
  unlocked = 'unlocked',
}

export enum VehiclePropulsionType {
  electric = 'electric',
  electric_assist = 'electric_assist',
  human = 'human',
}

export enum VehicleRewardType {
  rescue_stranded = 'rescue_stranded',
}

export enum VehicleState {
  available = 'available',
  non_operational = 'non_operational',
  on_trip = 'on_trip',
  rebalancing = 'rebalancing',
  removed = 'removed',
  uninitialised = 'uninitialised',
  unknown = 'unknown',
  waiting_for_location = 'waiting_for_location',
}

export enum VehicleType {
  bike = 'bike',
  cargo_bike = 'cargo_bike',
  scooter = 'scooter',
}

export enum ZoneType {
  normal = 'normal',
  workshop = 'workshop',
}

export interface AutomationFilters {
  justification_ids?: (string | null)[] | null
  prefix?: string | null
}

export interface BikeWhere {
  frame?: RemoteNodeOp | null
}

export interface BooleanOp {
  is_null?: boolean | null
  is_not_null?: boolean | null
  eq?: boolean | null
  ne?: boolean | null
}

export interface ConnectivityWhere {
  id?: ScalarOp | null
  is_online?: BooleanOp | null
}

export interface IInvoiceOrderby {
  id?: Direction | null
  created_at?: Direction | null
  description?: Direction | null
  type?: Direction | null
}

export interface IInvoiceWhere {
  id?: ScalarOp | null
  amount?: ScalarOp | null
  description?: ScalarOp | null
  type?: ScalarOp | null
  status?: ScalarOp | null
  user?: RemoteNodeOp | null
}

export interface IotStateWhere {
  id?: ScalarOp | null
  firmware_version?: ScalarOp | null
  hardware_version?: ScalarOp | null
  power_mode?: ScalarOp | null
  board_hw_type?: ScalarOp | null
}

export interface JobDetailsInput {
  reportedLocation?: string | null
  locationDescription?: string | null
  assetTag?: string | null
  bikeNumber?: string | null
  scheme_id?: string | null
  assignee?: string | null
  description?: string | null
  priority?: string | null
  assets?: string | null
  status?: JobStatusEnum | null
  taskId?: string | null
  role?: string | null
  notes?: string | null
  is_unlocked?: boolean | null
}

export interface JobInput {
  reportedLocation?: string | null
  locationDescription?: string | null
  assetTag?: string | null
  bikeNumber?: string | null
  priority?: string | null
  taskId?: string | null
  status?: JobStatusEnum | null
  notes?: string | null
  scheme_id?: string | null
  assignee?: string | null
}

export interface JobTaskInput {
  uuid?: string | null
  details?: TaskDetailsInput | null
}

export interface JobUpdateInput {
  details?: JobDetailsInput | null
  tasks?: JobTaskInput[] | null
}

export interface JourneyWhere {
  id?: ScalarOp | null
  user?: RemoteNodeOp | null
  scheme?: RemoteNodeOp | null
  module?: RemoteNodeOp | null
  completed_at?: ScalarOp | null
  started_at?: ScalarOp | null
}

export interface LocationAreaInput {
  latitude_min: number
  latitude_max: number
  longitude_min: number
  longitude_max: number
}

export interface ModuleOrderby {
  id?: Direction | null
  telemetry?: TelemetryOrderby | null
}

export interface ModuleWhere {
  id?: ScalarOp | null
  unlock_id?: ScalarOp | null
  hardware_type?: ScalarOp | null
  telemetry?: TelemetryWhere | null
  connectivity?: ConnectivityWhere | null
  iot_state?: IotStateWhere | null
  bike?: BikeWhere | null
  tags?: TagWhere | null
  vehicle?: VehicleWhere | null
}

export interface Paginate {
  page?: number | null
  per_page?: number | null
}

export interface PartInput {
  partName?: string | null
  serialNumber?: string | null
  batchNumber?: string | null
  status?: string | null
}

export interface RefundInvoiceV2InputTransaction {
  id: string
  amount: number
}

export interface RemoteNodeOp {
  id?: ScalarOp | null
}

export interface RoleInput {
  role_type_id: string
  scheme_id?: string | null
  org_id?: string | null
  user_id: string
}

export interface RoleTypeWhere {
  id?: ScalarOp | null
}

export interface RoleWhere {
  type?: RoleTypeWhere | null
}

export interface ScalarOp {
  is_null?: boolean | null
  is_not_null?: boolean | null
  eq?: string | null
  ne?: string | null
  gt?: string | null
  lt?: string | null
  ge?: string | null
  le?: string | null
  like?: string | null
  ilike?: string | null
  in?: (string | null)[] | null
  not_in?: (string | null)[] | null
}

export interface SchemeAvailabilityRestrictionInput {
  scheme_id: string
  vehicle_type: VehicleType
  frame_type: FrameType
  propulsion_type: VehiclePropulsionType
  start_time: string
  end_time: string
}

export interface ServiceAreaPolicyInput {
  speed_limit: number
  is_parking_allowed: boolean
  effective_from?: string | null
  effective_to?: string | null
  service_area_type?: ServiceAreaType | null
  applies_to?: VehicleType[] | null
}

export interface TagWhere {
  id?: ScalarOp | null
  name?: ScalarOp | null
  deleted?: BooleanOp | null
}

export interface TaskCodeItemInput {
  code: string
  reason: TaskCodeReason
}

export interface TaskConsumableInput {
  sku: string
  quantity: number
}

export interface TaskDetailsInput {
  assignee?: string | null
  description?: string | null
  status?: TaskStatusEnum | null
  title?: string | null
  faultIds?: (string | null)[] | null
  notes?: string | null
  parts?: (PartInput | null)[] | null
  task_codes_details?: TaskCodeItemInput[] | null
  allow_parts_tracking?: boolean | null
  replacements?: TaskReplacementInput[] | null
  consumables?: TaskConsumableInput[] | null
}

export interface TaskReplacementInput {
  modelName: string
  modelSysId: string
  previous?: string | null
  new: string
  reusability?: TaskReplacementReusability | null
  notes?: string | null
}

export interface TelemetryOrderby {
  id?: Direction | null
  time?: Direction | null
  last_fix_time?: Direction | null
  latitude?: Direction | null
  longitude?: Direction | null
  altitude?: Direction | null
  fix_invalid?: Direction | null
  battery_percent?: Direction | null
  battery_voltage?: Direction | null
  max_error?: Direction | null
  ambient_temperature?: Direction | null
  dyno_pulses?: Direction | null
}

export interface TelemetryWhere {
  id?: ScalarOp | null
  time?: ScalarOp | null
  last_fix_time?: ScalarOp | null
  latitude?: ScalarOp | null
  longitude?: ScalarOp | null
  altitude?: ScalarOp | null
  fix_invalid?: ScalarOp | null
  battery_percent?: ScalarOp | null
  battery_voltage?: ScalarOp | null
  max_error?: ScalarOp | null
  dyno_pulses?: ScalarOp | null
}

export interface UserOrderby {
  id?: Direction | null
  email?: Direction | null
}

export interface UserWhere {
  id?: ScalarOp | null
  email?: ScalarOp | null
  phone_number?: ScalarOp | null
  roles?: RoleWhere | null
}

export interface VehicleWhere {
  id?: ScalarOp | null
  frame_id?: ScalarOp | null
}

export interface WhitelistInput {
  pattern: string
  scheme_id: string
  role_type_id: number
}

export interface WhitelistOrderby {
  id?: Direction | null
  pattern?: Direction | null
}

export interface ZoneWhere {
  id?: ScalarOp | null
  name?: ScalarOp | null
  scheme?: RemoteNodeOp | null
}

//==============================================================
// END Enums and Input Objects
//==============================================================
