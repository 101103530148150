import { useState } from 'react'
import { Button, Modal } from 'antd'
import { Flex } from '@weareberyl/design-system'

import SetMemberRole from '../SetMemberRole'
import { UserListItemFragmentDoc } from 'gql/generated/graphql'
import client from 'client'

type Props = {
  selectedRowKeys: string[]
  clear: () => void
}

export const EditSelectedUsers = ({ selectedRowKeys, clear }: Props) => {
  const [open, setOpen] = useState(false)
  const onClose = () => {
    setOpen(false)
  }
  // We just got the data from the list query
  // So use the Apollo cache to look up the users for the selected IDs
  const members = selectedRowKeys.map(id =>
    client.readFragment({
      id: `User:${id}`,
      fragment: UserListItemFragmentDoc,
    }),
  )
  return (
    <>
      {selectedRowKeys.length > 0 && (
        <Flex p={3} alignItems="flex-start" gridGap="10px">
          <Button onClick={() => setOpen(!open)} type="primary">
            Edit {selectedRowKeys.length} selected users
          </Button>

          <Button onClick={clear}>Clear</Button>
        </Flex>
      )}
      <Modal
        open={open}
        zIndex={1050}
        width={550}
        bodyStyle={{ padding: 0 }}
        onCancel={() => setOpen(false)}
        footer={null}
        destroyOnClose
      >
        <SetMemberRole members={members} closeModal={onClose} />
      </Modal>
    </>
  )
}
