import { useState, Fragment } from 'react'
import { message, Modal, Button } from 'antd'
import { withRouter, RouteComponentProps } from 'react-router'
import pluralize from 'pluralize'
import { Button as BerylButton } from '@weareberyl/design-system'
import { useStart_DeploymentMutation } from 'gql/generated/graphql'

import SelectVersion from '../SelectVersion'
import * as routes from 'constants/routes'

type Props = RouteComponentProps & {
  module_ids: string[]
}

export default withRouter(({ module_ids, history }: Props) => {
  const [version, setVersion] = useState('')
  const [visible, setModalVisibility] = useState(false)

  const [startDeploymentMutation, { loading }] = useStart_DeploymentMutation({
    onCompleted: data => {
      message.info('Deployment Started!')
      history.push(`${routes.DEPLOYMENTS}/${data?.start_deployment?.id}`)
    },
    onError: err => {
      message.error(`Something went wrong: ${err.message}`)
    },
  })

  return (
    <Fragment>
      <Modal
        open={visible}
        title={`Update ${module_ids.length} ${pluralize(
          'module',
          module_ids.length,
        )}`}
        onCancel={() => {
          setModalVisibility(false)
        }}
        onOk={() => {
          startDeploymentMutation({
            variables: { module_ids, version },
          })
        }}
        footer={[
          <Button
            type="primary"
            key="cancel"
            onClick={() => setModalVisibility(false)}
          >
            Cancel
          </Button>,
          <Button
            type="primary"
            key="start"
            loading={loading}
            onClick={() => {
              startDeploymentMutation({
                variables: { module_ids, version },
              })
            }}
          >
            Submit
          </Button>,
        ]}
      >
        <span>Select Version: </span>
        <SelectVersion onChange={setVersion} />
      </Modal>
      <BerylButton
        type="primary"
        disabled={module_ids.length < 1}
        onClick={() => {
          setModalVisibility(true)
        }}
        title="Deploy"
      />
    </Fragment>
  )
})
