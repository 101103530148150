import { Flex, Box, Icon } from '@weareberyl/design-system'
import { ZoneType } from '__generated__/globalTypes'

type ZoneTypeStatusProps = {
  zoneType: ZoneType
  isPromotional?: boolean
}

const Star = () => (
  <Box
    borderRadius="100%"
    alignItems="center"
    bg="jaffa"
    mt="-12px"
    ml="-10px"
    p="2px"
    height={16}
    width={16}
  >
    <Icon color="white" mt="1px" type="star" width={10} height={10} />
  </Box>
)

const ZoneTypeStatus = ({ zoneType, isPromotional }: ZoneTypeStatusProps) => {
  const isWorkshop = zoneType === ZoneType.workshop

  return (
    <Flex flexWrap="nowrap" alignItems="center" justifyContent="center">
      <Box
        borderRadius="100%"
        alignItems="center"
        bg={isWorkshop ? 'tumeric' : 'berylGreen'}
        mt="3px"
        p="2px"
        height={24}
        width={24}
      >
        <Icon
          color="grape"
          mt="2px"
          type={isWorkshop ? 'spanner' : 'parking'}
          width={16}
          height={16}
        />
      </Box>
      {isPromotional && <Star />}
    </Flex>
  )
}

export default ZoneTypeStatus
