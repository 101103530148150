import React from 'react'
import { Flex, Box, Heading, Text, BoxProps } from '@weareberyl/design-system'
import { isNotNullOrUndefined } from 'utils/types'

export type SectionItem = {
  key: string
  value?: string | number | JSX.Element | null
}

type Props = {
  title?: string
  titleValue?: React.ReactNode
  titleStyle?: BoxProps
  items: SectionItem[]
} & BoxProps

class CardSection extends React.PureComponent<Props> {
  render() {
    const { title, titleValue, titleStyle, items, ...props } = this.props

    const sectionItems = items
      ?.filter(isNotNullOrUndefined)
      .map((item: SectionItem) => (
        <Flex
          key={item.key}
          justifyContent="space-between"
          width="100%"
          mb="2px"
        >
          <Text variant="small">{item.key}:</Text>
          <Text variant="small" fontFamily="Hellix-SemiBold">
            {item.value}
          </Text>
        </Flex>
      ))

    return (
      <Box flex="1" {...props}>
        {title && (
          <Flex
            justifyContent="space-between"
            width="100%"
            mt={3}
            mb={2}
            {...titleStyle}
          >
            <Heading variant="h4">{title}</Heading>
            {titleValue && <Heading variant="h4">{titleValue}</Heading>}
          </Flex>
        )}
        {sectionItems}
      </Box>
    )
  }
}

export default CardSection
