import React from 'react'
import { Tooltip } from 'antd'
import { colors, Box, BoxProps } from '@weareberyl/design-system'

import electricIcon from 'images/electric.svg'
import { ModuleHardware } from 'gql/generated/graphql'

type Props = {
  hardwareType: ModuleHardware
} & BoxProps

class HardwareTypeIcon extends React.PureComponent<Props> {
  render() {
    const { hardwareType, ...props } = this.props

    switch (hardwareType) {
      case ModuleHardware.bbe:
      case ModuleHardware.scooter:
        return (
          <Tooltip title={hardwareType}>
            <Box
              mr={2}
              height={18}
              width={18}
              borderRadius="100%"
              bg={colors.lake}
              {...props}
            >
              <img
                alt="electric"
                style={{
                  height: 18,
                  padding: 2,
                }}
                src={electricIcon}
              />
            </Box>
          </Tooltip>
        )

      default:
        return null
    }
  }
}

export default HardwareTypeIcon
