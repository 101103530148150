import React, { PureComponent } from 'react'
import ContainerDimensions from 'react-container-dimensions'
import { MapRef, StaticMap } from 'react-map-gl'
import config from 'constants/config'
import DeckGL, { FlyToInterpolator } from 'deck.gl'
import 'react-map-gl-geocoder/dist/mapbox-gl-geocoder.css'
import Geocoder from 'react-map-gl-geocoder'
import { GeocoderPin } from './layers'
import { debounce } from 'lodash'

export type ViewState = {
  latitude: number
  longitude: number
  zoom: number
  height: number
  width: number
  bounds: {
    _ne: {
      lat: number
      lng: number
    }
    _sw: {
      lat: number
      lng: number
    }
  }
}

export type BasisMapProps = {
  width: number
  height: number
  zoom: number
  latitude: number
  longitude: number
  x: number | null
  y: number | null
  zoneWhitelist: string[]
  hoveredObject: null
  iconWhitelist: []
  pollInterval?: number
  onViewStateChange?: (viewState: ViewState) => void
  layers: any[]
  message?: string
  hideGeocoder?: boolean
}

type BasisMapState = {
  viewState: any
  geocoderPosition: [number, number] | null
}

export class BasisMap extends PureComponent<BasisMapProps, BasisMapState> {
  static defaultProps = {
    width: 200,
    height: 200,
    zoom: 15,
    latitude: 51.5074,
    longitude: 0.1278,
    x: null,
    y: null,
    zoneWhitelist: [],
    hoveredObject: null,
    iconWhitelist: [],
  }

  _map: React.RefObject<MapRef> = React.createRef<MapRef>()
  _pollTimer: null | number = null

  constructor(props: BasisMapProps) {
    super(props)

    const { latitude, longitude, zoom, height, width } = props

    this.state = {
      viewState: {
        latitude,
        longitude,
        zoom,
        bearing: 0,
        pitch: 0,
        width,
        height,
      },
      geocoderPosition: null,
    }
  }

  componentDidMount() {
    this.propagateOnViewStateChange({ viewState: this.state.viewState })

    // If a pollInterval is passed and there is an onViewStateChange callback then start a timer
    if (this.props.pollInterval !== undefined && this.props.onViewStateChange) {
      this._startTimer()
    }
  }
  // You can update the centering of the map by updating the latitude and longitude props
  UNSAFE_componentWillReceiveProps(nextProps: BasisMapProps) {
    const { latitude, longitude } = nextProps
    if (
      latitude !== this.props.latitude ||
      longitude !== this.props.longitude
    ) {
      this.setState({
        ...this.state,
        viewState: { ...this.state.viewState, latitude, longitude },
      })
    }
  }

  _startTimer = () => {
    if (this._pollTimer !== null) {
      clearInterval(this._pollTimer)
    }

    this._pollTimer = window.setInterval(
      this._fetchData,
      this.props.pollInterval,
    )
  }

  _fetchData = () => {
    if (this._map.current) {
      const mapBoxMap = this._map.current.getMap()
      const center = mapBoxMap.getCenter()

      this.props.onViewStateChange?.({
        height: 0,
        width: 0,
        longitude: center[0],
        latitude: center[1],
        zoom: mapBoxMap.getZoom(),
        bounds: mapBoxMap.getBounds(),
      })

      // Restart the poll timer
      this._startTimer()
    }
  }

  propagateOnViewStateChange = debounce(({ viewState }) => {
    // tslint:disable
    if (this._map.current) {
      const mapBoxMap = this._map.current.getMap()

      if (this.props.onViewStateChange) {
        this.props.onViewStateChange({
          ...viewState,
          bounds: mapBoxMap.getBounds(),
        })

        // If there is a pollInterval then restart the poll timer
        if (this.props.pollInterval !== undefined) {
          this._startTimer()
        }
      }
    }
  }, 1000)

  _onViewStateChange = ({ viewState }) => {
    this.setState({ viewState })
    this.propagateOnViewStateChange({ viewState })
  }

  onGeocoderResult = ({ result }) => {
    if (!result) {
      return
    }
    const { center } = result
    this.setState({
      viewState: {
        ...this.state.viewState,
        longitude: center[0],
        latitude: center[1],
        zoom: 14,
        pitch: 0,
        bearing: 0,
        transitionDuration: 2000,
        transitionInterpolator: new FlyToInterpolator(),
      },
      geocoderPosition: center,
    })
  }

  onGeocoderClear = () => {
    this.setState({ geocoderPosition: null })
  }

  render() {
    const { layers, message, hideGeocoder } = this.props
    const { viewState, geocoderPosition } = this.state

    const filteredLayers = layers.filter(i => !!i)

    if (geocoderPosition) {
      filteredLayers.push(
        GeocoderPin({
          latitude: geocoderPosition[1],
          longitude: geocoderPosition[0],
        }),
      )
    }

    if (filteredLayers.length) {
      return (
        <div>
          <DeckGL
            controller
            viewState={viewState}
            layers={filteredLayers}
            onViewStateChange={this._onViewStateChange}
          >
            <StaticMap
              ref={this._map}
              mapboxApiAccessToken={config.REACT_APP_MAPBOX_TOKEN}
              mapStyle={config.REACT_APP_MAPBOX_STYLE_URL}
            />
            {!hideGeocoder && (
              <Geocoder
                mapRef={this._map}
                mapboxApiAccessToken={config.REACT_APP_MAPBOX_TOKEN}
                countries="GB"
                position="bottom-right"
                onResult={this.onGeocoderResult}
                onClear={this.onGeocoderClear}
              />
            )}
          </DeckGL>
          {this.props.children}
        </div>
      )
    }

    return (
      <div>
        <StaticMap
          ref={this._map}
          mapboxApiAccessToken={config.REACT_APP_MAPBOX_TOKEN}
          mapStyle={config.REACT_APP_MAPBOX_STYLE_URL}
          {...viewState}
        >
          <div className="map-overlay">
            <div>
              <h3>{message}</h3>
            </div>
          </div>
        </StaticMap>
      </div>
    )
  }
}

export default props => (
  <ContainerDimensions>
    <BasisMap {...props} />
  </ContainerDimensions>
)
