import { useEffect } from 'react'
import {
  Card,
  Form,
  Input,
  Button,
  InputNumber,
  DatePicker,
  Select,
  Col,
} from 'antd'
import { Flex } from '@weareberyl/design-system'
import type { Moment } from 'moment'

import type { ValidationRuleOption } from '../data'
import { useCurrentScheme } from 'hooks'
import { VehicleCategory } from 'gql/generated/graphql'
import { PlusOutlined } from '@ant-design/icons'
import SchemeSelect from 'components/Scheme/SchemeSelect'
import { VehicleCategorySelect } from './select-fields'

const labelColSpan = 8
const layout = {
  labelCol: { span: labelColSpan },
  wrapperCol: { span: 12 },
}

const tailLayout = {
  wrapperCol: { offset: labelColSpan, span: 12 },
}

export type FormValues = {
  name: string
  banner: string
  description: string
  scheme_ids: string[]
  vehicle_categories: VehicleCategory[]
  validation_rule_ids: string[]
  journey_first_free_minutes?: number
  journey_percent_off_time_based_charges?: number
  unlock_fee_percent_off?: number
  promotion_dates?: Moment[]
}

type Props = {
  showRuleModal: () => void
  // Shared from higher scope cos they get updated by the model form
  validationRuleOptions: ValidationRuleOption[]
  loadingRules: boolean
  saving: boolean
  values?: FormValues
}
const PromotionForm = ({
  showRuleModal,
  loadingRules,
  validationRuleOptions,
  saving,
  values,
}: Props) => {
  const [form] = Form.useForm()
  const { currentSchemeId } = useCurrentScheme()

  useEffect(() => {
    // Set intiial values, if this is an edit form
    if (values) {
      form.setFieldsValue(values)
    }
  }, [values, form])

  const onReset = () => {
    form.resetFields()
  }

  return (
    <Form {...layout} form={form} name="promotionForm">
      <Card title="Campaign details to display in the app">
        <Form.Item
          name="name"
          label="Campaign name"
          rules={[{ required: true, message: 'Please enter name' }]}
          tooltip="Displayed on the promotions screen"
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="description"
          label="Campaign description"
          tooltip="Displayed on the promotions screen when details are expanded"
          rules={[
            { required: true, message: 'Please provide a short description' },
          ]}
        >
          <Input.TextArea />
        </Form.Item>
        <Form.Item
          name="banner"
          label="Banner text"
          tooltip="This will be displayed in the app"
          rules={[
            {
              required: true,
              message: 'Please provide some banner text, to display in the app',
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="vehicle_categories"
          label="Vehicle type icons to show"
          tooltip="This configures which vehicle icons are shown on the promotion in the app."
          rules={[
            {
              required: true,
              message: 'Please select which vehicle types this campaign is for',
            },
          ]}
        >
          <VehicleCategorySelect mode="multiple" />
        </Form.Item>
      </Card>

      <Card title="Promotion setup">
        <Form.Item
          name="promotion_dates"
          label="Dates"
          rules={[
            {
              required: true,
              message: 'Please provide the start and end dates',
            },
          ]}
        >
          <DatePicker.RangePicker />
        </Form.Item>

        <Form.Item
          name="scheme_ids"
          label="Scheme IDs"
          rules={[
            {
              required: true,
            },
          ]}
          initialValue={[currentSchemeId]}
        >
          <SchemeSelect mode="multiple" />
        </Form.Item>
      </Card>

      <Card title="Reward config">
        <Form.Item
          name="unlock_fee_percent_off"
          label="Unlock fee percent off"
          initialValue={0}
        >
          <InputNumber
            min={0}
            max={100}
            step={10}
            formatter={value => `${value}%`}
            // @ts-expect-error (min max values set unexpected type for value)
            parser={value => parseInt(value.replace('%', ''))}
          />
        </Form.Item>

        <Form.Item
          name="journey_first_free_minutes"
          label="First free minutes"
          rules={[{ required: false }]}
          tooltip="Don't set this if you are doing a PaYR minutes percent off"
          initialValue={0}
        >
          <InputNumber min={0} max={100} />
        </Form.Item>

        <Form.Item
          name="journey_percent_off_time_based_charges"
          label="PaYR minutes percent off"
          rules={[{ required: false }]}
          tooltip="Minutes percent off is separate to unlock fee discount. Set this to the same percentage for an overall discount."
          initialValue={0}
        >
          <InputNumber
            min={0}
            max={100}
            step={10}
            formatter={value => `${value}%`}
            // @ts-expect-error (min max values set unexpected type for value)
            parser={value => parseInt(value.replace('%', ''))}
          />
        </Form.Item>
      </Card>

      <Card title="Eligibility criteria">
        <Form.Item
          name="validation_rule_ids"
          label="Validation rules"
          rules={[
            {
              required: true,
              message: 'Please select or create validation rules',
            },
          ]}
        >
          <Select options={validationRuleOptions} loading={loadingRules} />
        </Form.Item>

        <Col offset={labelColSpan}>
          <Button
            htmlType="button"
            style={{ margin: '8px 0 0', paddingLeft: '0.8em' }}
            onClick={showRuleModal}
          >
            <PlusOutlined />
            Create new validation rules
          </Button>
        </Col>
      </Card>

      <Card>
        <Form.Item {...tailLayout}>
          <Flex style={{ gap: '10px' }}>
            <Button type="primary" htmlType="submit" loading={saving}>
              Submit
            </Button>
            <Button type="dashed" onClick={onReset}>
              Reset
            </Button>
          </Flex>
        </Form.Item>
      </Card>
    </Form>
  )
}

export default PromotionForm
