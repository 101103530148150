import { Button } from '@weareberyl/design-system'
import { message, Modal, Select } from 'antd'
import { BaseOptionType } from 'antd/lib/select'
import { HoverText } from 'components/Journey/actions'
import { useSideloadOTAFirmwareMutation } from 'gql/generated/graphql'
import useCompatibleOTAFirmwares from 'hooks/OTA/useCompatibleOTAFirmwares'
import { useState } from 'react'

interface IOTASideload {
  id: string
}

const OTASideload = ({ id }: IOTASideload) => {
  const [modalOpen, setModalOpen] = useState(false)
  const [firmwareID, setFirmwareID] = useState<number | null>(null)
  const [sideLoadFirmware] = useSideloadOTAFirmwareMutation()
  const close = () => {
    setFirmwareID(null)
    setModalOpen(false)
  }

  const { data } = useCompatibleOTAFirmwares(id)
  const firmwareOptions: BaseOptionType[] = Object.values(
    (data ?? []).reduce((prev, f) => {
      if (!f) return prev
      const option = {
        value: f.id,
        label: f.firmware_version,
      }
      if (prev[f.firmware_type]) {
        prev[f.firmware_type].options.push(option)
      } else {
        prev[f.firmware_type] = {
          label: f.firmware_type,
          options: [option],
        }
      }
      return prev
    }, {}),
  )

  const buttonDisabled = firmwareOptions.length < 1

  const applyFirmware = async () => {
    if (!firmwareID) return

    try {
      await sideLoadFirmware({
        variables: { module_id: id, firmware_id: firmwareID },
      })
      message.info('Load firmware request sent!')
    } catch (e) {
      message.error(`Error requesting load firmware: ${e}`)
    } finally {
      close()
    }
  }

  return (
    <>
      <HoverText text="No firmware candidates found" disabled={!buttonDisabled}>
        <Button
          title="Load Firmware"
          onPress={() => {
            setModalOpen(true)
          }}
          disabled={buttonDisabled}
        />
      </HoverText>
      <Modal
        title="Load Firmware to Device"
        open={modalOpen}
        onCancel={close}
        onOk={applyFirmware}
        okText="Apply"
        okButtonProps={{ disabled: !firmwareID }}
        width={300}
      >
        <Select
          placeholder="Select a Firmware"
          value={firmwareID}
          onChange={value => setFirmwareID(value)}
          options={firmwareOptions}
          style={{ width: '100%' }}
        />
      </Modal>
    </>
  )
}

export default OTASideload
