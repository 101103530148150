import { useState } from 'react'

import { useZoneSetPromotionalMutation } from 'gql/generated/graphql'

type useBulkZonePromotionalMutationProps = {
  zoneIds: string[]
  onCompleted: (failedIds: string[]) => void
  onError: (err: unknown) => void
}

export function useBulkZonePromotionalMutation({
  zoneIds,
  onCompleted,
  onError,
}: useBulkZonePromotionalMutationProps): [
  (isPromotional: boolean) => void,
  boolean,
] {
  const [loading, setLoading] = useState(false)

  const [setPromotional] = useZoneSetPromotionalMutation({ onError })

  const updateFunction = (isPromotional: boolean) => {
    if (zoneIds.length !== 0) {
      let failedZoneIds: string[] = []
      setLoading(true)

      zoneIds.forEach(async (id, index) => {
        const isLastZone = index + 1 === zoneIds.length

        const refetchQueries = isLastZone ? ['ZonesList'] : undefined

        let result: Awaited<ReturnType<typeof setPromotional>>

        try {
          result = await setPromotional({
            variables: { id, is_promotional: isPromotional },
            refetchQueries,
          })
        } finally {
          // Whether it worked or not, set loading false when it gets to the last zone
          if (isLastZone) {
            setLoading(false)
            onCompleted(failedZoneIds)
          }
        }
        if (!result || result?.errors) {
          failedZoneIds = [...failedZoneIds, id]
        }
      })
    }
  }

  return [updateFunction, loading]
}
