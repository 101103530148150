import { Fragment } from 'react'
import { useHistory } from 'react-router'
import { ApolloQueryResult } from '@apollo/client'
import { Flex, Card, Heading, Button } from '@weareberyl/design-system'

import type {
  ZonesListQuery,
  ZonesListQueryVariables,
  ZonesListQuery_table_ZoneConnection_nodes_Zone as Zone,
} from 'gql/generated/graphql'
import * as routes from 'constants/routes'
import HeadTitle from 'components/HeadTitle'
import Search from 'components/Zone/Search'
import SuspendedStatus from 'components/Zone/SuspendedStatus'
import TabTitle from 'components/shared/TabTitle'
import Table from 'components/Table'
import Tabs from 'components/shared/Tabs'
import ZoneLink from 'components/ZoneLink'
import ZoneTypeStatus from '../ZoneTypeStatus'
import { VehicleIcon } from 'utils'
import { useQueryParams } from 'utils/useQueryParams'
import { ZoneListItem, useZoneList } from './data'
import { ColumnProps, TableProps } from 'antd/lib/table'
import { VehicleType } from '__generated__/globalTypes'
import EditSelectedZones from './BatchEdit'
import { useCurrentScheme } from 'hooks'

const { Tab } = Tabs
const id = 'zones-table'

const getColumns = (searchValue: string) => {
  const columns: ColumnProps<ZoneListItem>[] = [
    {
      title: 'Type',
      dataIndex: 'zone_type',
      render: (zoneType, zone) => (
        <ZoneTypeStatus
          zoneType={zoneType}
          isPromotional={zone.is_promotional}
        />
      ),
    },
    {
      title: 'Name',
      render: ({ id }) => <ZoneLink id={id} />,
    },
    {
      title: 'Hireable Vehicles',
      dataIndex: 'hireable_modules_count',
    },
    {
      title: 'Total Vehicles',
      dataIndex: 'all_commissioned_modules_count',
    },
    {
      title: 'Capacity',
      dataIndex: 'capacity',
    },
    {
      title: 'Permitted vehicles',
      dataIndex: 'permitted_vehicles',
      render: permitted_vehicles => (
        <Flex>
          {permitted_vehicles.map((vehicle: VehicleType) => (
            <VehicleIcon key={vehicle} vehicle={vehicle} size={21} />
          ))}
        </Flex>
      ),
    },
  ]

  if (searchValue) {
    columns.push({
      title: 'Status',
      dataIndex: 'is_suspended',
      render: isSuspended => <SuspendedStatus isSuspended={isSuspended} />,
    })
  }

  return columns
}

type ZoneTableProps = {
  searchValue: string
  onChange: TableProps<ZoneListItem>['onChange']
  props: ApolloQueryResult<ZonesListQuery>
  rowSelection?: TableProps<ZoneListItem>['rowSelection']
}

const ZoneTable = ({
  searchValue,
  rowSelection,
  onChange,
  props,
}: ZoneTableProps) => {
  return (
    <Table
      id={id}
      columns={getColumns(searchValue)}
      onChange={onChange}
      {...props}
      rowSelection={rowSelection ? { ...rowSelection } : undefined}
    />
  )
}

export type TabOptions = 'suspended' | 'enabled'

type Props = Record<string, never>

const ZoneList = (_: Props) => {
  const history = useHistory()
  const { currentSchemeId } = useCurrentScheme()
  const [
    { currentTab, current, pageSize, searchValue, ...queryParams },
    setQueryParams,
  ] = useQueryParams(id, {
    currentTab: 'enabled',
  })
  const viewingSuspended = currentTab === 'suspended'
  const selectedRowKeys = queryParams.selectedRowKeys ?? []

  const resetProperties = (
    currentTab: TabOptions = 'enabled',
    ids: string[] = [],
  ) => {
    setQueryParams({ currentTab, selectedRowKeys: ids })
  }

  const onSearch = (text: string) => {
    resetProperties('enabled')
    setQueryParams({ searchValue: text, current: 1 })
  }

  const onChangeSelection = (selectedRowKeys: string[], _: Zone[]) =>
    setQueryParams({ selectedRowKeys }, true)

  const variables: ZonesListQueryVariables = {
    scheme_id: currentSchemeId,
    where: searchValue ? [{ name: { like: `%${searchValue}%` } }] : [],
    paginate: {
      page: current,
      per_page: pageSize,
    },
  }

  if (!searchValue) {
    variables.is_suspended = viewingSuspended
  }

  const tableProps = useZoneList(variables)

  const tableBorderProps = !viewingSuspended
    ? { borderTopLeftRadius: 0 }
    : { borderTopRightRadius: 0 }

  return (
    <>
      <HeadTitle pageTitle="Bays" />
      <Card p={5}>
        <Flex justifyContent="space-between" alignItems="flex-end" mb={5}>
          <Heading variant="callout">Bays</Heading>
          <Button
            title="Sync"
            onPress={() => {
              history.push(`/scheme/${currentSchemeId}${routes.ZONES_SYNC}`)
            }}
            variant="primary"
            width="260px"
          />
        </Flex>
        <Flex maxWidth="520px" mb={4}>
          <Search onSubmit={onSearch} />
        </Flex>
        {searchValue ? (
          <Fragment>
            <Heading variant="h1" my={2}>
              Search results for: {searchValue}
            </Heading>
            <ZoneTable
              searchValue={searchValue}
              onChange={({ current, pageSize }) =>
                setQueryParams({ current, pageSize })
              }
              props={tableProps}
            />
          </Fragment>
        ) : (
          <Card variant="gray" p={3}>
            <Tabs>
              <Tab
                onPress={() => resetProperties('enabled')}
                isSelected={!viewingSuspended}
              >
                <TabTitle tabText="Enabled" icon="tick" />
                <Heading variant="callout">
                  {tableProps.data.enabled_zones.pagination.total}
                </Heading>
              </Tab>
              <Tab
                onPress={() => resetProperties('suspended')}
                isSelected={viewingSuspended}
              >
                <TabTitle tabText="Suspended" icon="cross" />
                <Heading variant="callout">
                  {tableProps.data.suspended_zones.pagination.total}
                </Heading>
              </Tab>
            </Tabs>
            <Card variant="borderless" {...tableBorderProps}>
              <EditSelectedZones
                selectedRowKeys={selectedRowKeys}
                resetProperties={resetProperties}
                currentTab={currentTab}
              />
              <ZoneTable
                searchValue={searchValue}
                rowSelection={{
                  selectedRowKeys,
                  onChange: onChangeSelection,
                  preserveSelectedRowKeys: true,
                }}
                onChange={({ current, pageSize }) =>
                  setQueryParams({ current, pageSize })
                }
                props={tableProps}
              />
            </Card>
          </Card>
        )}
      </Card>
    </>
  )
}

export default ZoneList
