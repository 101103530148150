import { useState, ChangeEvent, useEffect } from 'react'
import { isEmpty, startCase } from 'lodash'
import { ScanOutlined } from '@ant-design/icons'
import { Form } from '@ant-design/compatible'
import '@ant-design/compatible/assets/index.css'
import { Input, Button, message, Select } from 'antd'
import SchemeSelect from 'components/Scheme/SchemeSelect'
import { CommissionVehicle, DecommissionVehicle, MoveScheme } from './actions'
import { showLockConfig, getDefaultLockConfig } from 'utils'
import { useSchemePermittedVehicles } from 'hooks'

import {
  ModuleVehicleCommissionedQuery_module_Module,
  PermittedVehicleModel,
} from 'gql/generated/graphql'
import { LockConfiguration, FrameType } from '__generated__/globalTypes'
import useCommissionDetails from 'hooks/useCommissionDetails'

const FormItem = Form.Item

type BikeEditProps = {
  module: ModuleVehicleCommissionedQuery_module_Module
}

const BikeEdit = ({ module }: BikeEditProps) => {
  const [editing, setEditing] = useState(false)
  const [schemeId, setSchemeId] = useState(module?.vehicle?.detail?.scheme?.id)
  const [frameId, setFrameId] = useState(module?.vehicle?.frame_id)
  const [vehicleUnlockId, setVehicleUnlockId] = useState<string | undefined>(
    module?.vehicle?.detail?.vehicle_unlock_id || undefined,
  )
  const [lock_configuration, setLockConfiguration] = useState(
    module?.vehicle?.detail?.lock_configuration,
  )
  const [selectedFrameType, setSelectedFrameType] = useState<
    FrameType | undefined
  >(module?.vehicle?.frame_type as FrameType | undefined)
  const isCommissioning = isEmpty(module?.vehicle?.detail)
  const [getPermittedVehicles, schemePermittedVehicles] =
    useSchemePermittedVehicles()
  const [availableFrameTypes, setAvailableFrameTypes] = useState<
    PermittedVehicleModel[]
  >([])
  const { setCommissionDetails, currentCommissionDetails } =
    useCommissionDetails()

  useEffect(() => {
    if (!currentCommissionDetails || !editing) return
    const { schemeId, frameId, vehicleUnlockId, frameType, lockConfiguration } =
      currentCommissionDetails
    setSchemeId(schemeId)
    setFrameId(frameId)
    setVehicleUnlockId(vehicleUnlockId)
    setSelectedFrameType(frameType)
    setLockConfiguration(lockConfiguration)
  }, [currentCommissionDetails, editing])

  useEffect(() => {
    if (!showLockConfig(schemeId)) {
      // for all schemes that don't use LockConfiguration
      // we want to auto set it to the default.
      setLockConfiguration(
        getDefaultLockConfig(schemeId, module?.hardware_type),
      )
    }
  }, [schemeId, module?.hardware_type])

  useEffect(() => {
    // Find permitted frame types for this module's hardware type
    const permittedVehicles = schemePermittedVehicles || []
    const moduleFrameTypes: PermittedVehicleModel[] = permittedVehicles.reduce(
      (acc: PermittedVehicleModel[], item: PermittedVehicleModel) => {
        if (item.supported_hardware_types.includes(module?.hardware_type)) {
          // Format for select dialogue
          acc.push(item as PermittedVehicleModel)
        }
        return acc
      },
      [],
    )
    setAvailableFrameTypes(moduleFrameTypes)
    if (moduleFrameTypes.length > 0) {
      setSelectedFrameType(moduleFrameTypes[0].frame_type)
    }
  }, [schemePermittedVehicles, module?.hardware_type])

  const onChangeFrameId = (event: ChangeEvent<HTMLInputElement>) => {
    setFrameId(event.target.value.trim())
  }

  const onChangeVehicleUnlockId = (event: ChangeEvent<HTMLInputElement>) => {
    setVehicleUnlockId(event.target.value.trim())
  }

  const onCommission = () => {
    setEditing(false)
    message.info('Bike commission success')
    setCommissionDetails(null)
  }

  const onDecommission = () => {
    setEditing(false)
    setSchemeId(undefined)
    setFrameId(undefined)
    setLockConfiguration(undefined)
    setVehicleUnlockId(undefined)
    message.info('Bike decommission success')
  }

  const onMoveScheme = () => {
    setEditing(false)
    message.info('Scheme successfully moved!')
  }

  const onSchemeChange = (scheme: string) => {
    setSchemeId(scheme)
    getPermittedVehicles(scheme)
  }

  const beforeDecommission = () => {
    if (schemeId && frameId && lock_configuration) {
      setCommissionDetails({
        schemeId,
        frameId,
        vehicleUnlockId,
        frameType: selectedFrameType,
        lockConfiguration: lock_configuration,
      })
    }
  }

  return (
    <Form className="login-form" layout="vertical">
      <FormItem label="Scheme">
        <SchemeSelect
          disabled={!editing}
          onChange={onSchemeChange}
          scheme_id={schemeId}
        />
      </FormItem>
      <FormItem label="Frame ID">
        <Input
          disabled={!editing || !isCommissioning}
          onChange={onChangeFrameId}
          name="frame_id"
          prefix={<ScanOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
          placeholder="Frame ID"
          value={frameId}
        />
      </FormItem>
      {availableFrameTypes.length > 1 && (
        <FormItem label="Frame Type">
          <Select
            disabled={!editing || !isCommissioning}
            value={selectedFrameType}
            placeholder="Please select a frame type"
            onChange={(frameType?: FrameType) =>
              setSelectedFrameType(frameType)
            }
          >
            {availableFrameTypes.map(item => {
              return (
                <Select.Option key={item.frame_type} value={item.frame_type}>
                  {startCase(item.frame_type)}
                </Select.Option>
              )
            })}
          </Select>
        </FormItem>
      )}
      {showLockConfig(schemeId) && (
        <FormItem label="Lock Configuration">
          <Select
            disabled={!editing || !isCommissioning}
            value={lock_configuration}
            placeholder="Please select a lock_configuration"
            onChange={(v: LockConfiguration | null) =>
              setLockConfiguration(v ?? undefined)
            }
          >
            {Object.keys(LockConfiguration).map(key => {
              return (
                <Select.Option key={key} value={key}>
                  {key}
                </Select.Option>
              )
            })}
          </Select>
        </FormItem>
      )}
      <FormItem
        label="Vehicle Unlock ID"
        help="Both this unlock id and the module unlock id can be used to unlock the
          vehicle. Use when the module is not visible (BBE2)."
      >
        <Input
          disabled={!editing || !isCommissioning}
          onChange={onChangeVehicleUnlockId}
          name="vehicle_unlock_id"
          placeholder="Vehicle Unlock ID"
          value={vehicleUnlockId}
        />
      </FormItem>
      <FormItem>
        <Button
          style={{ marginRight: '8px' }}
          onClick={() => setEditing(!editing)}
        >
          {editing ? 'Cancel' : 'Edit'}
        </Button>
        {editing && isCommissioning && (
          <CommissionVehicle
            module_id={module?.id}
            frame_id={frameId}
            scheme_id={schemeId}
            onCompleted={onCommission}
            lock_configuration={lock_configuration}
            frame_type={selectedFrameType ?? undefined}
            vehicle_unlock_id={vehicleUnlockId ?? undefined}
          />
        )}
        {editing && !isCommissioning && (
          <>
            <MoveScheme
              module_id={module?.id}
              scheme_id={schemeId}
              onCompleted={onMoveScheme}
              disabled={
                !schemeId || schemeId === module?.vehicle?.detail?.scheme?.id
              }
              style={{ marginRight: '8px' }}
            />
            <DecommissionVehicle
              module_id={module?.id}
              beforeCompleted={beforeDecommission}
              onCompleted={onDecommission}
            />
          </>
        )}
      </FormItem>
    </Form>
  )
}

export default BikeEdit
