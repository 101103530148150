import { Card, Divider } from 'antd'
import { useState } from 'react'
import Telemetry from './Telemetry'
import Bike from 'components/Bike'
import OTADeviceInformation from 'components/OTA/OTADeviceInformation'
import Unlock from './unlock'

interface IModuleDetails {
  id: string
  isMaptexVehicle?: boolean
}

const ModuleDetails = ({ id, isMaptexVehicle = false }: IModuleDetails) => {
  const [currentTab, setCurrentTab] = useState('telemetry')
  const tabs = [
    {
      key: 'telemetry',
      tab: 'Telemetry',
    },
    ...(isMaptexVehicle
      ? [
          {
            key: 'otaConfig',
            tab: 'Device Configuration',
          },
        ]
      : []),
    {
      key: 'commission',
      tab: 'Commission',
    },
  ]
  const content = {
    telemetry: <Telemetry id={id} />,
    otaConfig: <OTADeviceInformation id={id} />,
    commission: (
      <>
        <Bike module_id={id} />
        <Divider />
        <Unlock module_id={id} />
      </>
    ),
  }

  return (
    <Card
      activeTabKey={currentTab}
      onTabChange={t => setCurrentTab(t)}
      tabList={tabs}
      style={{ fontFamily: 'Hellix-SemiBold' }}
    >
      {content[currentTab]}
    </Card>
  )
}

export default ModuleDetails
