import { useState, useEffect } from 'react'
import { Heading, Box } from '@weareberyl/design-system'

import Copy from '../Copy'
import { auth } from 'utils/firebase'

const Token = () => {
  const [token, setToken] = useState<string | null>(null)

  useEffect(() => {
    const getToken = async () => {
      if (auth.currentUser) {
        const token = await auth.currentUser.getIdToken(true)
        setToken(token)
      }
    }

    getToken()
  }, [])

  return (
    <>
      <Box mb={3}>
        <Heading variant="h1">
          Authentication Token
          <span> – {auth.currentUser?.email}</span>
        </Heading>
      </Box>
      <Copy id="token" text={token ?? ''} />
    </>
  )
}

export default Token
