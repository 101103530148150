import { Form, Input, Card } from 'antd'
import { useHistory } from 'react-router-dom'

import * as routes from 'constants/routes'
import { useCurrentScheme } from 'hooks'
import { useQueryParams } from 'utils/useQueryParams'
import { FilterType } from './data'

type Props = {
  loading: boolean
}

const UserSearch = ({ loading }: Props) => {
  const [form] = Form.useForm()
  const history = useHistory()
  const { currentSchemeId } = useCurrentScheme()
  const [queryParams, setQueryParams] = useQueryParams('users-table')

  // As soon as something looks like a user ID, navigate to it
  const onIDChange: React.ChangeEventHandler<HTMLInputElement> = event => {
    const pattern = new RegExp(/^[a-zA-Z0-9]{28,36}$/)
    const userId = event.currentTarget.value
    if (!pattern.test(userId)) {
      return
    }
    const userPath = `${routes.USERS}/${userId}`
    const userPathWithScheme = currentSchemeId
      ? routes.prependScheme(userPath, currentSchemeId)
      : userPath
    history.push(userPathWithScheme)
  }

  const onSubmitEmail = (value: string) => {
    setQueryParams({
      filterType: FilterType.email,
      search: value.trim(),
      current: 1,
    })
  }

  const onSubmitPhone = (value: string) => {
    setQueryParams({
      filterType: FilterType.phone_number,
      search: value.trim(),
      current: 1,
    })
  }

  // Set form values from querystring
  const emailValue =
    queryParams.filterType === FilterType.email ? queryParams.search : ''
  const phoneValue =
    queryParams.filterType === FilterType.phone_number ? queryParams.search : ''

  return (
    <Card style={{ background: '#f7f7f7' }}>
      <Form form={form} layout="inline" style={{ gap: '10px' }}>
        <Form.Item label="Email" name="email" initialValue={emailValue}>
          <Input.Search
            loading={loading}
            onSearch={onSubmitEmail}
            placeholder="email@example.com"
            allowClear
          />
        </Form.Item>
        <Form.Item label="Phone" name="phone_number" initialValue={phoneValue}>
          <Input.Search
            addonBefore={'+'}
            loading={loading}
            onSearch={onSubmitPhone}
            placeholder="44 7712 345678"
            allowClear
          />
        </Form.Item>
        <Form.Item label="ID" tooltip="Quick link: paste a user ID in here">
          <Input onChange={onIDChange} placeholder="Paste ID here" />
        </Form.Item>
      </Form>
    </Card>
  )
}

export default UserSearch
