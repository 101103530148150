import qs, { ParseOptions } from 'query-string'
import { useMemo } from 'react'
import { useLocation, useHistory } from 'react-router-dom'

export type defaultQueryParams = {
  current: number
  pageSize: number
}

export type QueryParams = {
  [key: string]: any
}

const QUERY_STRING_OPTIONS = { arrayFormat: 'bracket' } as ParseOptions

export const useQueryParams = (
  id: string, // used to namespace the url params.
  opts?: QueryParams,
): [
  defaultQueryParams & QueryParams,
  (params: QueryParams, replace?: boolean) => void,
] => {
  const history = useHistory()
  const location = useLocation()

  const defaultQueryParams = {
    current: 1,
    pageSize: 20,
    ...opts,
  }

  let queryParams = qs.parse(
    location.search,
    QUERY_STRING_OPTIONS,
  ) as QueryParams

  if (id && queryParams.id !== id) {
    // if useQueryParams is passed an ID.
    // we only return the query params if
    // the id matches that in the URL.
    queryParams = defaultQueryParams
  }

  // convert pageSize + current for graphql
  const cleanedQueryParams = useMemo(
    () => ({
      ...queryParams,
      current: parseInt(queryParams.current),
      pageSize: parseInt(queryParams.pageSize),
    }),
    [queryParams],
  )

  const setter = (newValue: QueryParams, replace = false) => {
    if (Object.keys(newValue).length === 0) {
      history.replace(location.pathname)
    } else {
      history[replace ? 'replace' : 'push']({
        search: qs.stringify(
          {
            id,
            ...cleanedQueryParams,
            ...newValue,
          },
          QUERY_STRING_OPTIONS,
        ),
      })
    }
  }

  return [cleanedQueryParams, setter]
}

export const withQueryParams = (id: string, Component, opts) => props => {
  const [queryParams, setQueryParams] = useQueryParams(id, opts)

  return (
    <Component
      {...props}
      queryParams={queryParams}
      setQueryParams={setQueryParams}
    />
  )
}
