import { useApolloClient } from '@apollo/client'
import config from 'constants/config'
import { OTAListBucketFirmwaresDocument } from 'gql/generated/graphql'
import { useEffect, useState } from 'react'
import { auth } from 'utils/firebase'

const useUploadFirmware = () => {
  const [token, setToken] = useState('')
  const client = useApolloClient()

  useEffect(() => {
    const getAndSetToken = async () => {
      const token = await auth.currentUser?.getIdToken(true)
      if (!token) return
      setToken(token)
    }
    getAndSetToken()
  }, [])

  const mutationFunction = async (formData: FormData) => {
    if (!token) {
      return null
    }
    const response = await fetch(
      config.REACT_APP_GQL_ENDPOINT.replace(/graphql/, 'firmware/bucket'),
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        method: 'POST',
        body: formData,
      },
    )

    const data = await response.json()

    if (response.status !== 201) {
      throw new Error(
        `Received HTTP ${response.status}: ${JSON.stringify(data)}`,
      )
    }

    await client.refetchQueries({ include: [OTAListBucketFirmwaresDocument] })

    return data
  }

  return mutationFunction
}

export default useUploadFirmware
