import { Route, Switch } from 'react-router-dom'
import * as routes from 'constants/routes'
import Journey from 'components/Journey'
import JourneyList from 'components/Journey/List'
import InvoiceList from 'components/Invoice/List'
import Invoice from 'components/Invoice'
import User from 'components/User'
import UserList from 'components/User/List'
import MapView from 'components/MapView'
import ModuleList from 'components/Module/List'
import ModuleView from 'components/Module'
import ProductList from 'components/Product/List'
import Zone from 'components/Zone'
import ZoneHistory from 'components/Zone/History'
import ZoneList from 'components/Zone/List'
import ZoneSync from 'components/Zone/Sync'
import ServiceAreaList from 'components/ServiceArea/List'
import ServiceAreaSync from 'components/ServiceArea/Sync'
import ServiceAreaEdit from 'components/ServiceArea/Edit'
import Whitelist from 'components/Whitelist'
import VehicleCurfews from 'components/Curfew'
import JobList from 'components/Job/List'
import Job from 'components/Job'
import { SchemePersistRoute, SchemeRedirect } from 'components/Scheme/Redirect'
import { PermissionOnly, requirePermissions } from 'components/Session'
import CampaignList from 'components/Campaigns/List'
import CampaignEdit from 'components/Campaigns/Edit'
import CampaignCreate from 'components/AutoPromos/Create'
import ValidationRuleList from 'components/PromotionValidationRules/List'

// Create a component with baked in permission checks to prevent re-mounting when the route changes
const UserListWithPermission = requirePermissions(UserList, 'user.view', {})
const ZoneListWithPermission = requirePermissions(ZoneList, 'zone.view', {})

// These routes all require a scheme to be in the URL
// Often the current scheme is accessed from a local query
// If the scheme in the URL is different from the saved one, it saves the new one from the URL
const SchemeContext = () => (
  <>
    <SchemePersistRoute path={routes.SCHEME} />
    <SchemeRedirect path={routes.MAP} />
    <Route
      exact
      path={routes.withScheme(routes.MAP)}
      component={() => (
        <PermissionOnly.Forbidden permission="map.view">
          <MapView />
        </PermissionOnly.Forbidden>
      )}
    />
    <Route
      exact
      path={routes.withScheme(routes.MODULES)}
      component={() => (
        <PermissionOnly.Forbidden permission="bike.view">
          <ModuleList />
        </PermissionOnly.Forbidden>
      )}
    />
    <Route
      exact
      path={routes.withScheme(routes.SERVICE_AREAS_SYNC)}
      component={({ match }) => (
        <PermissionOnly.Forbidden permission="service_area.edit">
          <ServiceAreaSync scheme_id={match.params.scheme_id} />
        </PermissionOnly.Forbidden>
      )}
    />
    <Route
      exact
      path={routes.withScheme(routes.SERVICE_AREA)}
      component={({ match }) => (
        <PermissionOnly.Forbidden permission="service_area.edit">
          <ServiceAreaEdit id={match.params.id} />
        </PermissionOnly.Forbidden>
      )}
    />
    <Route
      exact
      path={routes.withScheme(routes.SERVICE_AREAS)}
      component={() => (
        <PermissionOnly.Forbidden permission="service_area.view">
          <ServiceAreaList />
        </PermissionOnly.Forbidden>
      )}
    />
    <Switch>
      <Route
        exact
        path={routes.withScheme(routes.ZONES_SYNC)}
        component={({ match }) => (
          <PermissionOnly.Forbidden permission="zone.edit">
            <ZoneSync scheme_id={match.params.scheme_id} />
          </PermissionOnly.Forbidden>
        )}
      />
      <Route
        exact
        path={routes.withScheme(routes.ZONE)}
        component={({ match }) => (
          <PermissionOnly.Forbidden permission="zone.view">
            <Zone id={match.params.id} schemeId={match.params.scheme_id} />
          </PermissionOnly.Forbidden>
        )}
      />
      <Route
        exact
        path={routes.withScheme(routes.ZONE_HISTORY)}
        component={({ match }) => (
          <PermissionOnly.Forbidden permission="zone.view">
            <ZoneHistory
              id={match.params.id}
              schemeId={match.params.scheme_id}
            />
          </PermissionOnly.Forbidden>
        )}
      />
    </Switch>
    <Route
      exact
      path={routes.withScheme(routes.ZONES)}
      component={ZoneListWithPermission}
    />
    <Route
      exact
      path={[routes.MODULE, routes.withScheme(routes.MODULE)]}
      component={({ match }) => (
        <PermissionOnly.Forbidden permission="bike.view">
          <ModuleView id={match.params.id} />
        </PermissionOnly.Forbidden>
      )}
    />
    <Route
      exact
      path={routes.withScheme(routes.JOURNEYS)}
      component={() => (
        <PermissionOnly.Forbidden permission="journey.view">
          <JourneyList />
        </PermissionOnly.Forbidden>
      )}
    />
    <Route
      exact
      path={[routes.JOURNEY, routes.withScheme(routes.JOURNEY)]}
      component={({ match }) => (
        <PermissionOnly.Forbidden permission="journey.view">
          <Journey id={match.params.id} />
        </PermissionOnly.Forbidden>
      )}
    />
    <Route
      exact
      path={routes.withScheme(routes.PRODUCTS)}
      component={() => (
        <PermissionOnly.Forbidden permission="product.view">
          <ProductList />
        </PermissionOnly.Forbidden>
      )}
    />
    <Route
      exact
      path={[routes.INVOICE, routes.withScheme(routes.INVOICE)]}
      component={({ match }) => (
        <PermissionOnly.Forbidden permission="invoice.view">
          <Invoice id={match.params.id} />
        </PermissionOnly.Forbidden>
      )}
    />
    <Route
      exact
      path={routes.withScheme(routes.INVOICES)}
      component={() => (
        <PermissionOnly.Forbidden permission="invoice.view">
          <InvoiceList />
        </PermissionOnly.Forbidden>
      )}
    />
    <Route
      exact
      path={routes.withScheme(routes.USERS)}
      component={UserListWithPermission}
    />
    <Route
      exact
      path={[routes.USER, routes.withScheme(routes.USER)]}
      component={({ match }) => (
        <PermissionOnly.Forbidden permission="user.view">
          <User id={match.params.id} />
        </PermissionOnly.Forbidden>
      )}
    />
    <Route
      exact
      path={routes.withScheme(routes.WHITELISTS)}
      component={() => (
        <PermissionOnly.Forbidden permission="whitelist.view">
          <Whitelist />
        </PermissionOnly.Forbidden>
      )}
    />
    <Route
      exact
      path={routes.withScheme(routes.CURFEW)}
      component={() => (
        <PermissionOnly permission="vehicle_curfew.view">
          <VehicleCurfews />
        </PermissionOnly>
      )}
    />
    <Route
      exact
      path={routes.withScheme(routes.JOBS)}
      component={() => (
        <PermissionOnly.Forbidden permission="job.view">
          <JobList showMap />
        </PermissionOnly.Forbidden>
      )}
    />
    <Route
      exact
      path={[routes.JOB, routes.withScheme(routes.JOB)]}
      component={({ match }) => (
        <PermissionOnly.Forbidden permission="job.view">
          <Job id={match.params.id} />
        </PermissionOnly.Forbidden>
      )}
    />
    <Route
      exact
      path={[routes.CAMPAIGNS, routes.withScheme(routes.CAMPAIGNS)]}
      component={() => (
        <PermissionOnly.Forbidden permission="promotion_builder.edit">
          <CampaignList />
        </PermissionOnly.Forbidden>
      )}
    />
    <Route
      exact
      path={[routes.NEW_CAMPAIGN, routes.withScheme(routes.NEW_CAMPAIGN)]}
      component={() => (
        <PermissionOnly.Forbidden permission="promotion_builder.edit">
          <CampaignCreate />
        </PermissionOnly.Forbidden>
      )}
    />
    <Route
      exact
      path={[routes.CAMPAIGN, routes.withScheme(routes.CAMPAIGN)]}
      component={({ match }) => (
        <PermissionOnly.Forbidden permission="promotion_builder.edit">
          <CampaignEdit id={match.params.id} />
        </PermissionOnly.Forbidden>
      )}
    />
    <Route
      exact
      path={[
        routes.VALIDATION_RULES,
        routes.withScheme(routes.VALIDATION_RULES),
      ]}
      component={() => (
        <PermissionOnly.Forbidden permission="promotion_builder.edit">
          <ValidationRuleList />
        </PermissionOnly.Forbidden>
      )}
    />
  </>
)

export default SchemeContext
