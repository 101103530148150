import {
  type ZonesListQueryVariables,
  type ZonesListQuery_Query,
  useZonesListQuery,
  ZonesListQuery_table_ZoneConnection_nodes_Zone,
} from 'gql/generated/graphql'

// Define a skeleton of data so the table can be shown
// with the loading state if there isn't any data yet
export const defaultData: ZonesListQuery_Query = {
  __typename: 'Query',
  table: {
    __typename: 'ZoneConnection',
    nodes: [],
    pagination: {
      __typename: 'Pagination',
      total: 0,
      current: 0,
      pageSize: 0,
    },
  },
  enabled_zones: {
    __typename: 'ZoneConnection',
    pagination: {
      __typename: 'Pagination',
      total: 0,
    },
  },
  suspended_zones: {
    __typename: 'ZoneConnection',
    pagination: {
      __typename: 'Pagination',
      total: 0,
    },
  },
}

export type ZoneListItem = ZonesListQuery_table_ZoneConnection_nodes_Zone

export const useZoneList = (variables: ZonesListQueryVariables) => {
  const { data, ...rest } = useZonesListQuery({ variables, pollInterval: 0 })
  const tableData = data ?? defaultData
  return { data: tableData, ...rest }
}
