import React, { useState } from 'react'
import { PlusOutlined } from '@ant-design/icons'
import { Select, Tag } from 'antd'
import { colors } from '@weareberyl/design-system'
import { ModuleTagName } from '../../../__generated__/globalTypes'

const { Option } = Select

type Props = {
  tags: ModuleTagName[]
  onComplete: (tagName: ModuleTagName) => Promise<void>
}

export default ({ tags, onComplete }: Props) => {
  const unusedTags = Object.keys(ModuleTagName).filter(
    (t: ModuleTagName) => !tags.includes(t) && t !== 'vandalism',
  )

  if (unusedTags.length === 0) {
    return null
  }

  const [addingTag, updateAddingTag] = useState(false)
  const [dropdownOpen, updateDropdownOpen] = useState(false)

  if (!addingTag) {
    return (
      <Tag
        onClick={() => updateAddingTag(true)}
        style={{
          background: colors.tofu,
          border: 'none',
          borderRadius: 24,
          padding: '4px 14px',
          fontFamily: 'Hellix-SemiBold',
          cursor: 'pointer',
        }}
      >
        Add tag <PlusOutlined />
      </Tag>
    )
  }

  const hideDropDown = () => {
    updateDropdownOpen(false)
    updateAddingTag(false)
  }

  return (
    <Select
      placeholder="Tag"
      style={{ width: 160, marginRight: 8 }}
      open={dropdownOpen}
      autoFocus
      onFocus={() => updateDropdownOpen(true)}
      onChange={async (tagName: ModuleTagName) => {
        hideDropDown()
        await onComplete(tagName)
      }}
      onBlur={hideDropDown}
    >
      {unusedTags.map(name => (
        <Option key={name}>{name}</Option>
      ))}
    </Select>
  )
}
