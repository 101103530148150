import { DownOutlined } from '@ant-design/icons'
import { Dropdown as AntDropdown } from 'antd'
import type { DropDownProps } from 'antd/lib/dropdown'

import { Flex, Box, Heading } from '@weareberyl/design-system'

type DropdownProps = {
  title?: string
} & DropDownProps

const Dropdown = ({
  overlay,
  menu,
  trigger = ['click'],
  title = 'Actions',
  disabled = false,
  children,
  ...rest
}: DropdownProps) => {
  return (
    <AntDropdown
      trigger={trigger}
      overlay={overlay}
      menu={menu}
      disabled={disabled}
      overlayStyle={{
        boxShadow: '0 8px 16px 0 rgba(0, 0, 0, 0.18)',
        borderRadius: '4px',
        overflow: 'hidden',
      }}
      {...rest}
    >
      {children ?? (
        <Box
          px={4}
          py={3}
          borderRadius="4px"
          style={{
            boxShadow: '0 2px 8px 0 rgba(0, 0, 0, 0.18)',
            cursor: disabled ? 'not-allowed' : 'pointer',
          }}
        >
          <Flex justifyContent="space-between">
            <Heading testID="dropdown-title" variant="h4" pr={2}>
              {title}
            </Heading>
            <DownOutlined style={{ fontSize: 18, paddingTop: 2 }} />
          </Flex>
        </Box>
      )}
    </AntDropdown>
  )
}

export default Dropdown
