import {
  Box,
  Badge,
  colors,
  Flex,
  Heading,
  Text,
} from '@weareberyl/design-system'
import { Icon as LegacyIcon } from '@ant-design/compatible'
import { iconByIsAutomated } from 'utils'
import { isNotNullOrUndefined } from 'utils/types'
import { JobQuery_job_Job } from 'gql/generated/graphql'

const CreatedBy = ({ isAutomated }: { isAutomated: boolean }) => {
  const [type, color, text] = iconByIsAutomated(isAutomated)
  return (
    <Flex mb={3} mt={2} justifyContent="start" alignItems="center">
      <LegacyIcon
        type={type}
        style={{
          fontSize: 20,
          color,
        }}
      />
      <Text ml={1} align="center" style={{ fontSize: 15 }} color={color}>
        {text}
      </Text>
    </Flex>
  )
}

const Justifications = ({
  job,
  isJustified,
}: {
  job: JobQuery_job_Job
  isJustified: boolean
}) => {
  if (!isJustified) {
    return null
  }
  const justifications = job.justifications?.filter(isNotNullOrUndefined)
  return (
    <Box>
      <Heading>Justifications</Heading>
      <Flex justifyContent="start">
        {justifications?.map(job => (
          <Badge
            key={job.justification_id}
            m={1}
            px={3}
            style={{
              backgroundColor: colors.mint,
              border: 'none',
            }}
            text={job.description}
          />
        ))}
      </Flex>
    </Box>
  )
}

export default ({ job }: { job: JobQuery_job_Job }) => {
  const isJustified = !!job.justifications?.length
  return (
    <Box>
      <CreatedBy isAutomated={isJustified} />
      <Justifications job={job} isJustified={isJustified} />
    </Box>
  )
}
