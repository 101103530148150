import { Card } from 'antd'
import { useEffect, useState } from 'react'
import { Scheme } from 'types'
import { useCurrentUser } from 'hooks'
import { hasPermission } from '../Session'
import JourneyList from 'components/Journey/List/Table'
import AssetJobs from '../Job/List/AssetJobs'

interface IJourneysAndJobs {
  id: string
  assetTag?: string
  scheme?: Scheme | null
}

const JourneysAndJobs = ({ id, assetTag, scheme }: IJourneysAndJobs) => {
  const [user] = useCurrentUser()
  const [currentTab, setCurrentTab] = useState<string | undefined>(undefined)

  const canViewJourneys =
    user &&
    scheme?.id &&
    hasPermission(user?.roles, 'journey.view', parseInt(scheme.id))
  const canViewJobs = Boolean(assetTag)

  useEffect(() => {
    if (canViewJourneys) setCurrentTab('journey')
    else if (canViewJobs) setCurrentTab('job')
  }, [canViewJourneys, canViewJobs])

  if (!canViewJourneys && !canViewJobs) return null

  const tabs = [
    ...(canViewJourneys
      ? [
          {
            key: 'journey',
            tab: 'Journeys',
          },
        ]
      : []),
    ...(canViewJobs
      ? [
          {
            key: 'job',
            tab: 'Jobs',
          },
        ]
      : []),
  ]
  const content = {
    journey: <JourneyList module_id={id} />,
    job: (
      <AssetJobs assetTag={assetTag as string} scheme={scheme as Scheme} slim />
    ),
  }

  return (
    <Card
      activeTabKey={currentTab}
      onTabChange={t => setCurrentTab(t)}
      tabList={tabs}
      style={{ fontFamily: 'Hellix-SemiBold' }}
    >
      {currentTab && content[currentTab]}
    </Card>
  )
}

export default JourneysAndJobs
