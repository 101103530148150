import { Dispatch, SetStateAction, useState } from 'react'
import {
  Heading,
  Box,
  Text,
  TextInput,
  colors,
  Icon,
  Flex,
  Button,
  borderRadii,
  Touchable,
  Card,
} from '@weareberyl/design-system'
import { useMutation, gql, ApolloQueryResult } from '@apollo/client'
import { message } from 'antd'

import type { CURRENT_USER } from 'types'
import PrimitiveDropdown from 'components/shared/PrimitiveDropdown'
import Query from 'components/Query'
import Roles from './Roles'
import {
  GET_SCHEMES_ORGS_AND_ROLE_TYPES,
  GET_SCHEMES_ORGS_AND_ROLE_TYPES_schemes_nodes,
  GET_SCHEMES_ORGS_AND_ROLE_TYPES_orgs_nodes,
  GET_SCHEMES_ORGS_AND_ROLE_TYPES_role_types,
} from 'components/User/__generated__/GET_SCHEMES_ORGS_AND_ROLE_TYPES'
import { USERS_LIST_table_nodes_roles } from 'components/User/List/__generated__/USERS_LIST'
import { formatRoleName } from '../../utils/index'
import filterSelectableSchemes from 'utils/filterSelectableSchemes'
import { withUser } from 'components/Session'
import { UserActionsDocument } from 'gql/generated/graphql'

const SUCCESS_MESSAGE_DURATION = 5
const FAILURE_MESSAGE_DURATION = 10

export const ADD_ROLE = gql`
  mutation ADD_ROLE($role: RoleInput!) {
    add_role(role: $role) {
      id
    }
  }
`

const GET_SCHEMES_ORGS_AND_ROLE_TYPES_QUERY = gql`
  query GET_SCHEMES_ORGS_AND_ROLE_TYPES {
    schemes(paginate: { per_page: 100 }) {
      nodes {
        id
        name
      }
    }
    orgs {
      nodes {
        id
        name
      }
    }
    role_types {
      id
      description
    }
  }
`

type MemberDetails = {
  email: string
  id: string
  roles?: Array<USERS_LIST_table_nodes_roles>
  isNewMember: boolean
}

type RefetchQueries = {
  query: any
  variables: any
}

type SetMemberRoleProps = {
  members: MemberDetails[]
  closeModal: () => void
  refetchQueries?: RefetchQueries[]
}

type SelectValueType = {
  value?: string
  id?: string
}

type EditResults = {
  email: string
  success: boolean
}

const SetMemberRole = ({
  members,
  closeModal,
  refetchQueries = [],
}: SetMemberRoleProps) => {
  const [loading, setLoading] = useState(false)
  const [memberRole, setMemberRole] = useState<SelectValueType>({
    value: '',
    id: '',
  })
  const [memberScheme, setMemberScheme] = useState<SelectValueType>({
    value: '',
    id: '',
  })
  const [memberOrg, setMemberOrg] = useState<SelectValueType>({
    value: '',
    id: '',
  })

  const { value: roleValue, id: roleId } = memberRole
  const { id: schemeId } = memberScheme
  const { id: orgId } = memberOrg

  const [addRole] = useMutation(ADD_ROLE)

  const getAddRolePromises = () => {
    return members.map(member => {
      return addRole({
        variables: {
          role: {
            role_type_id: roleId,
            scheme_id: schemeId || undefined,
            org_id: orgId || undefined,
            user_id: member.id,
          },
        },
        refetchQueries: [
          ...refetchQueries,
          { query: UserActionsDocument, variables: { user_id: member.id } },
        ],
      })
    })
  }

  const handleFulfillmentStatus = (addRoleResult, index) => {
    if (addRoleResult.status === 'fulfilled') {
      return { email: members[index].email, success: true }
    } else return { email: members[index].email, success: false }
  }

  const addRolesForUsers = async () => {
    setLoading(true)
    const addRolePromises = getAddRolePromises()
    const resultFulfillmentStatus = await Promise.allSettled(addRolePromises)
    const editResults = resultFulfillmentStatus.map(handleFulfillmentStatus)
    setLoading(false)
    return editResults
  }

  const getFailureMessage = (failedEdits: EditResults[]) => {
    const affectedUserEmails = failedEdits.map(user => user.email)
    return (
      <div style={{ textAlign: 'left' }}>
        <p>Something went wrong with these users:</p>
        <ul>
          {affectedUserEmails.map(email => (
            <li key={email}>{email}</li>
          ))}
        </ul>
      </div>
    )
  }

  const handleAddRolesForUsersResults = async () => {
    const addRolesResults = await addRolesForUsers()
    closeModal()
    const failedEdits = addRolesResults.filter(
      result => result.success === false,
    )
    if (failedEdits.length > 0) {
      message.error(getFailureMessage(failedEdits), FAILURE_MESSAGE_DURATION)
    } else
      message.success('User roles successfully added', SUCCESS_MESSAGE_DURATION)
  }

  const disableSubmit = !roleValue || loading

  return (
    <>
      <Box py={4} px={6} zIndex={2}>
        <Query
          query={GET_SCHEMES_ORGS_AND_ROLE_TYPES_QUERY}
          waitFor="data.schemes"
          pollInterval={0}
        >
          {({ data }: ApolloQueryResult<GET_SCHEMES_ORGS_AND_ROLE_TYPES>) => (
            <SetMemberRoleForm
              schemes={data?.schemes?.nodes}
              memberScheme={memberScheme}
              setMemberScheme={setMemberScheme}
              orgs={data?.orgs?.nodes}
              memberOrg={memberOrg}
              setMemberOrg={setMemberOrg}
              roleValue={roleValue}
              setMemberRole={setMemberRole}
              refetchQueries={refetchQueries}
              roleTypes={data?.role_types}
              users={members}
            />
          )}
        </Query>
      </Box>
      <Card
        p={null}
        px={6}
        py={4}
        borderTopLeftRadius={0}
        borderTopRightRadius={0}
        variant="gray"
      >
        <Button
          title="Save"
          onPress={handleAddRolesForUsersResults}
          loading={loading}
          disabled={disableSubmit}
        />
      </Card>
    </>
  )
}

type SetMemberRoleFormProps = {
  users: MemberDetails[]
  schemes: Array<GET_SCHEMES_ORGS_AND_ROLE_TYPES_schemes_nodes>
  memberScheme: SelectValueType
  setMemberScheme: Dispatch<SetStateAction<{ value: string; id: string }>>
  orgs: Array<GET_SCHEMES_ORGS_AND_ROLE_TYPES_orgs_nodes>
  roleTypes: Array<GET_SCHEMES_ORGS_AND_ROLE_TYPES_role_types>
  memberOrg: SelectValueType
  setMemberOrg: Dispatch<SetStateAction<{ value: string; id: string }>>
  roleValue: string
  setMemberRole: Dispatch<SetStateAction<{ value: string; id: string }>>
  refetchQueries?: RefetchQueries[]
  user: CURRENT_USER
}

const SetMemberRoleForm = withUser(
  ({
    users,
    schemes,
    memberScheme: { value: schemeValue, id: schemeId },
    setMemberScheme,
    orgs,
    roleTypes,
    memberOrg: { value: orgValue, id: orgId },
    setMemberOrg,
    roleValue,
    setMemberRole,
    refetchQueries = [],
    user, //aka the currentUser
  }: SetMemberRoleFormProps) => {
    const isSingleUser = users.length === 1
    const isNewMember = isSingleUser && users[0].isNewMember === true
    const userRoles = users[0]['roles']
    const userId = users[0]['id']

    const userRefetchQuery = isSingleUser
      ? { query: UserActionsDocument, variables: { user_id: userId } }
      : null
    const [showSuccessMessage, setShowSuccessMessage] = useState(isNewMember)
    const selectableSchemes = filterSelectableSchemes(schemes, user.roles)

    const isMultiUser = users.length > 1
    const getUserEmails = users.map(user => user.email)

    return (
      <>
        <Heading variant="callout" pt={2} pb={4}>
          {isNewMember ? 'Add user info' : 'Edit user info'}
        </Heading>
        {isSingleUser && showSuccessMessage && (
          <Box bg={colors.mint} p={4} my={3} borderRadius={borderRadii.default}>
            <Flex justifyContent="space-between">
              <Heading color={colors.pickle} variant="h2" mb={1}>
                Member successfully created
              </Heading>
              <Touchable onPress={() => setShowSuccessMessage(false)}>
                <Icon type="cross" width={22} height={22} color="pickle" />
              </Touchable>
            </Flex>
            <Heading variant="h4" color={colors.pickle}>
              Please select their role
            </Heading>
          </Box>
        )}
        {isMultiUser ? (
          <>
            <Text fontFamily="Hellix-SemiBold" variant="large">
              List of selected users
            </Text>
            <Text mb={3}>{getUserEmails.join('\n')}</Text>
          </>
        ) : (
          <>
            <Text>Email</Text>
            <TextInput value={getUserEmails} disabled opacity={0.5} />
          </>
        )}
        <Text my={1}>Scheme</Text>
        <PrimitiveDropdown
          items={[
            { name: '-', key: '' },
            ...selectableSchemes.map(scheme => ({
              name: scheme.name,
              key: scheme.id,
            })),
          ]}
          value={schemeValue ?? ''}
          onChange={newValue => {
            setMemberScheme({
              value: newValue.name,
              id: newValue.key,
            })
          }}
          placeholder="Select Scheme"
          disabled={!!orgId}
          zIndex={10}
        />
        <Text my={1}>Organisation</Text>
        <PrimitiveDropdown
          items={[
            { name: '-', key: '' },
            ...orgs.map(org => ({
              name: org.name,
              key: org.id,
            })),
          ]}
          value={orgValue ?? ''}
          onChange={newValue => {
            setMemberOrg({
              value: newValue.name,
              id: newValue.key,
            })
          }}
          placeholder="Select Organisation"
          disabled={!!schemeId}
          zIndex={9}
        />
        <Text my={1}>Role</Text>
        {isSingleUser && userRoles && (
          <Flex flexWrap="wrap">
            <Roles
              roles={userRoles}
              refetchQueries={[...refetchQueries, userRefetchQuery]}
              closable
            />
          </Flex>
        )}
        <PrimitiveDropdown
          items={roleTypes.map(role => ({
            name: role.description
              ? formatRoleName(role.description)
              : `Role: ${role.id}`,
            key: role.id,
          }))}
          value={roleValue}
          onChange={newValue => {
            setMemberRole({
              value: newValue.name,
              id: newValue.key,
            })
          }}
          placeholder="Add role"
          zIndex={8}
        />
      </>
    )
  },
)

export default SetMemberRole
