import { Fragment, useState } from 'react'
import Query from 'components/Query'
import { Pagination } from 'antd'
import { gql, ApolloQueryResult } from '@apollo/client'
import Timeline from '../Timeline'

import {
  MODULE_DOCK_HISTORY,
  MODULE_DOCK_HISTORYVariables,
} from './__generated__/MODULE_DOCK_HISTORY'

const MODULE_DOCK_HISTORY_QUERY = gql`
  query MODULE_DOCK_HISTORY($id: ID!, $paginate: Paginate) {
    module(module_id: $id) {
      id
      dock_state_history(paginate: $paginate) {
        nodes {
          id
          time
          message
        }
        pagination {
          current: page
          pageSize: per_page
          total
        }
      }
    }
  }
`

export default ({ id }: MODULE_DOCK_HISTORYVariables) => {
  const [page, setPage] = useState(1)
  const [perPage, setPerPage] = useState(10)

  const onPaginationChange = (clickedPage: number, pageSize: number) => {
    setPage(clickedPage)
    setPerPage(pageSize)
  }

  return (
    <Query
      waitFor="data.module.dock_state_history.nodes"
      query={MODULE_DOCK_HISTORY_QUERY}
      variables={{ id, paginate: { page, per_page: perPage } }}
    >
      {({ data }: ApolloQueryResult<MODULE_DOCK_HISTORY>) => {
        const { nodes, pagination } = data?.module?.dock_state_history || {}

        return (
          <Fragment>
            <Timeline items={nodes ?? []} />
            <Pagination
              hideOnSinglePage
              showSizeChanger
              pageSizeOptions={['20', '40', '60']}
              current={pagination?.current}
              total={pagination?.total}
              pageSize={perPage}
              onChange={onPaginationChange}
              onShowSizeChange={onPaginationChange}
              style={{ float: 'right' }}
            />
          </Fragment>
        )
      }}
    </Query>
  )
}
