import React, { Fragment } from 'react'
import Denied from './Denied'
import withUser from './withUser'
import { withScheme } from 'components/Scheme'
import {
  IRole,
  isSuperAdmin,
  isSuperWorkshop,
  hasRoleForScheme,
  isSchemeStaff,
  isSuperMarketing,
} from 'utils/firebase/authentication'
import { SCHEME, CURRENT_USER } from 'types'
import { OFFICE_ROLE_TYPES } from 'constants/roles'

type ConditionFn = ({
  user,
  scheme,
  permission,
}: {
  user: CURRENT_USER
  scheme: SCHEME
  permission: Permission
}) => boolean

type PermissionHOC = React.SFC<any> & { Forbidden?: any }

const shield =
  (condition: ConditionFn) =>
  ({ user, scheme, permission, children, denied }) => {
    if (condition({ user, scheme, permission })) {
      return <Fragment>{children}</Fragment>
    } else {
      return denied || null
    }
  }

const withForbidden = PermissionComponent => props =>
  <PermissionComponent {...props} denied={<Denied />} />

export const AuthOnly: PermissionHOC = withUser(shield(({ user }) => !!user.id))

AuthOnly.Forbidden = withForbidden(AuthOnly)

export const AdminOnly: PermissionHOC = withScheme(
  withUser(
    shield(({ user, scheme }) => {
      if (user.is_super_admin) {
        return true
      }

      if (
        user.scheme_admin &&
        user.scheme_admin.includes(parseInt(scheme.id))
      ) {
        return true
      }
      return false
    }),
  ),
)

AdminOnly.Forbidden = withForbidden(AdminOnly)

export type Permission = string &
  (
    | 'map.view'
    | 'bike.view'
    | 'zone.view'
    | 'zone.edit'
    | 'service_area.view'
    | 'service_area.edit'
    | 'journey.view'
    | 'job.view'
    | 'vehicle_curfew.view'
    | 'vehicle_curfew.edit'
    | 'invoice.view'
    | 'user.view'
    | 'user.all'
    | 'product.view'
    | 'validation_rule.view'
    | 'validation_rule.edit'
    | 'campaign.view'
    | 'campaign.edit'
    | 'promotion.view'
    | 'journey.start'
    | 'module.actions'
    | 'provision.view'
    | 'whitelist.view'
    | 'deployment.view'
    | 'module.all'
    | 'job.all'
    | 'super_admin'
    | 'scheme_staff'
    | 'global.view'
    | 'advanced.view'
  )

// skipcq JS-R1005
export const hasPermission = (
  roles: IRole[],
  permission: Permission,
  scheme_id: number,
) => {
  switch (permission) {
    case 'map.view':
    case 'bike.view':
    case 'zone.view':
    case 'zone.edit':
    case 'service_area.view':
    case 'service_area.edit':
    case 'journey.view':
    case 'job.view':
    case 'vehicle_curfew.view':
    case 'scheme_staff':
      return isSchemeStaff(roles, scheme_id)
    case 'vehicle_curfew.edit':
      return (
        isSuperAdmin(roles) ||
        hasRoleForScheme(roles, 'operations_lead', scheme_id) ||
        hasRoleForScheme(roles, 'admin', scheme_id)
      )
    case 'user.all':
      return (
        isSuperAdmin(roles) ||
        OFFICE_ROLE_TYPES.some(role => hasRoleForScheme(roles, role, null))
      )
    case 'invoice.view':
    case 'user.view':
      return (
        isSuperAdmin(roles) ||
        OFFICE_ROLE_TYPES.some(role => hasRoleForScheme(roles, role, scheme_id))
      )
    case 'product.view':
      return (
        isSuperAdmin(roles) ||
        isSuperMarketing(roles) ||
        hasRoleForScheme(roles, 'marketing', scheme_id) ||
        OFFICE_ROLE_TYPES.some(role => hasRoleForScheme(roles, role, scheme_id))
      )

    case 'promotion.view':
      return (
        isSuperAdmin(roles) ||
        isSuperMarketing(roles) ||
        hasRoleForScheme(roles, 'marketing', scheme_id) ||
        OFFICE_ROLE_TYPES.some(role => hasRoleForScheme(roles, role, scheme_id))
      )

    case 'validation_rule.edit':
      return (
        isSuperAdmin(roles) ||
        isSuperMarketing(roles) ||
        hasRoleForScheme(roles, 'marketing', null)
      )

    case 'validation_rule.view':
      return (
        isSuperAdmin(roles) ||
        isSuperMarketing(roles) ||
        hasRoleForScheme(roles, 'marketing', scheme_id) ||
        OFFICE_ROLE_TYPES.some(role => hasRoleForScheme(roles, role, scheme_id))
      )

    case 'campaign.view':
      return (
        isSuperAdmin(roles) ||
        isSuperMarketing(roles) ||
        hasRoleForScheme(roles, 'marketing', scheme_id) ||
        OFFICE_ROLE_TYPES.some(role => hasRoleForScheme(roles, role, scheme_id))
      )
    case 'campaign.edit':
      return (
        isSuperAdmin(roles) ||
        isSuperMarketing(roles) ||
        hasRoleForScheme(roles, 'marketing', scheme_id) ||
        hasRoleForScheme(roles, 'admin', scheme_id) ||
        hasRoleForScheme(roles, 'operations_lead', scheme_id)
      )

    case 'journey.start':
      return (
        isSuperAdmin(roles) ||
        hasRoleForScheme(roles, 'operations_lead', scheme_id) ||
        hasRoleForScheme(roles, 'admin', scheme_id)
      )

    case 'module.actions':
      return (
        isSuperAdmin(roles) ||
        isSuperWorkshop(roles) ||
        (isSchemeStaff(roles, scheme_id) &&
          !hasRoleForScheme(roles, 'read_only', scheme_id))
      )

    case 'advanced.view':
    case 'provision.view':
      return isSuperAdmin(roles) || hasRoleForScheme(roles, 'provisioner', null)

    case 'whitelist.view':
    case 'deployment.view':
      return isSuperAdmin(roles)

    case 'global.view':
    case 'module.all':
    case 'job.all':
      return (
        isSuperAdmin(roles) ||
        hasRoleForScheme(roles, 'second_line_support', null)
      )

    case 'super_admin':
      return isSuperAdmin(roles)

    default: {
      // TypeScript will enforce this case as never if all cases are handled
      const _exhaustiveCheck: never = permission
      return _exhaustiveCheck
    }
  }
}

export const PermissionOnly: PermissionHOC = withScheme(
  withUser(
    shield(({ user, scheme, permission }) => {
      return hasPermission(user.roles, permission, parseInt(scheme.id))
    }),
  ),
)

PermissionOnly.Forbidden = withForbidden(PermissionOnly)

export const SuperAdminOnly: PermissionHOC = withUser(
  shield(({ user }) => user.is_super_admin),
)

SuperAdminOnly.Forbidden = withForbidden(SuperAdminOnly)

/**
 * HOC to wrap a component, specifiying permissions required to view it
 * This is so that the permission wrapper can be used on routes without wraping them in the render function
 * of the Route, which causes re-mounting whenever the route changes
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function requirePermissions<C extends React.ComponentType<any>>(
  Component: C,
  permission: string,
  props: React.ComponentProps<C>,
) {
  return () => (
    <PermissionOnly.Forbidden permission={permission}>
      <Component {...props} />
    </PermissionOnly.Forbidden>
  )
}

export { withUser }
