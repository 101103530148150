import { ExclamationCircleFilled, UploadOutlined } from '@ant-design/icons'
import { Button } from '@weareberyl/design-system'
import {
  Col,
  Divider,
  Form,
  Input,
  message,
  Modal,
  Radio,
  Row,
  Select,
  Upload,
} from 'antd'
import { useForm } from 'antd/lib/form/Form'
import { RcFile } from 'antd/lib/upload'
import {
  FirmwareType,
  HardwareModel,
  OTAFirmwareInput,
  OTAFirmwaresDocument,
  useUpsertOTAFirmwareMutation,
} from 'gql/generated/graphql'
import useOTABucketFirmwaresList from 'hooks/OTA/useOTABucketFirmwaresList'
import useUploadFirmware from 'hooks/OTA/useUploadFirmware'
import { useEffect, useState } from 'react'

const css = String.raw

const formItemLayout = {
  labelCol: {
    span: 8,
  },
  wrapperCol: {
    span: 14,
  },
}

const commonRules = [{ required: true, message: 'field is required' }]

const versionRules = [
  {
    pattern: /(?=(^[0-9A-F]{4})$|(^[0-9A-F]{8}$)|(^[0-9A-Z]{9})$).*[^0]+.*/,
    message:
      'version must be a non-zero hex string of length 4, 8, or a non-zero alphanumeric string of length 9',
  },
]

const VALID_CONTENT_TYPES = [
  'application/macbinary',
  'application/octet-stream',
]

const CreateOTAFirmwareModal = () => {
  const [form] = useForm()
  const [modalOpen, setModalOpen] = useState(false)
  const [fileStrategy, setFileStrategy] = useState<'upload' | 'select'>(
    'upload',
  )
  const [fileToUpload, setFileToUpload] = useState<RcFile | null>(null)
  const fileList = fileToUpload ? [fileToUpload] : []
  const [addFirmware] = useUpsertOTAFirmwareMutation({
    refetchQueries: [OTAFirmwaresDocument],
  })
  const uploadFirmware = useUploadFirmware()
  const { data: bucketFirmwares } = useOTABucketFirmwaresList()
  const fileNameOptions = (bucketFirmwares || []).map(fileName => ({
    label: fileName,
    value: fileName,
  }))

  const hardware_model = Form.useWatch('hardware_model', form)
  const firmware_type = Form.useWatch('firmware_type', form)

  useEffect(() => {
    if (fileStrategy === 'select') {
      setFileToUpload(null)
    }
  }, [fileStrategy])

  useEffect(() => {
    if (
      hardware_model !== HardwareModel.a200_p &&
      firmware_type === FirmwareType.repeater
    ) {
      form.setFieldValue('firmware_type', null)
    }
  }, [hardware_model, firmware_type, form])

  const handleUpload = async () => {
    if (!fileToUpload) return

    const formData = new FormData()
    formData.append('file', fileToUpload, fileToUpload.name)

    await uploadFirmware(formData)
  }

  const onFinish = async (values: OTAFirmwareInput) => {
    if (fileStrategy === 'upload') {
      try {
        await handleUpload()
        values.file_name = (fileToUpload as RcFile).name
      } catch (e) {
        message.error(`Error uploading firmware: ${e}`)
        return
      }
    }
    setModalOpen(false)

    try {
      await addFirmware({ variables: { input: values } })
      message.success('Firmware successfully added!')
    } catch (e) {
      message.error(`Error adding new firmware: ${e}`)
    } finally {
      setFileToUpload(null)
      form.resetFields()
    }
  }

  const beforeUpload = (file: RcFile) => {
    if (VALID_CONTENT_TYPES.includes(file.type)) {
      if (fileNameOptions.map(({ label }) => label).includes(file.name)) {
        Modal.confirm({
          title: 'Duplicate firmware detected',
          icon: <ExclamationCircleFilled />,
          content: `A file called '${file.name}' already exists.`,
          onOk: () => {
            setFileStrategy('select')
            form.setFieldValue('file_name', file.name)
          },
          okText: 'Use existing file',
          style: { top: 200 },
        })
      } else {
        setFileToUpload(file)
      }
    } else {
      message.error(
        `Invalid file type: ${
          file.type
        }, firmware must be one of ${VALID_CONTENT_TYPES.join(', ')}`,
      )
    }
    return false
  }

  return (
    <>
      <Button title="Add Firmware" onPress={() => setModalOpen(true)} />
      <Modal
        title="Add Firmware"
        open={modalOpen}
        onOk={() => form.submit()}
        onCancel={() => setModalOpen(false)}
      >
        <Form form={form} onFinish={onFinish}>
          <Form.Item
            label="Model"
            name="hardware_model"
            rules={commonRules}
            {...formItemLayout}
          >
            <Select>
              <Select.Option value={HardwareModel.a200_p}>
                Segway A200P
              </Select.Option>
              <Select.Option value={HardwareModel.es400}>
                Okai ES400A/B Gen 1 IoT
              </Select.Option>
              <Select.Option value={HardwareModel.es400_gen2}>
                Okai ES400A/B Gen 2 IoT
              </Select.Option>
              <Select.Option value={HardwareModel.es410}>
                Okai ES410
              </Select.Option>
              <Select.Option value={HardwareModel.es600_gen2}>
                Okai ES600 Gen 2 IoT
              </Select.Option>
            </Select>
          </Form.Item>
          <Form.Item
            label="Component"
            name="firmware_type"
            rules={commonRules}
            {...formItemLayout}
          >
            <Select>
              <Select.Option value={FirmwareType.ecu}>ECU</Select.Option>
              <Select.Option value={FirmwareType.iot}>IoT</Select.Option>
              <Select.Option value={FirmwareType.meter}>Meter</Select.Option>
              {hardware_model === HardwareModel.a200_p && (
                <Select.Option value={FirmwareType.repeater}>
                  Repeater
                </Select.Option>
              )}
            </Select>
          </Form.Item>
          <Form.Item
            label="Hardware Version"
            name="hardware_version"
            rules={[...commonRules, ...versionRules]}
            {...formItemLayout}
          >
            <Input
              onChange={e =>
                form.setFieldValue(
                  'hardware_version',
                  e.target.value.toUpperCase(),
                )
              }
            />
          </Form.Item>
          <Form.Item
            label="Firmware Version"
            name="firmware_version"
            rules={[...commonRules, ...versionRules]}
            {...formItemLayout}
          >
            <Input
              onChange={e =>
                form.setFieldValue(
                  'firmware_version',
                  e.target.value.toUpperCase(),
                )
              }
            />
          </Form.Item>
          <Divider />
          <Row style={{ marginBottom: 24 }}>
            <Col span={formItemLayout.labelCol.span} />
            <Col span={formItemLayout.wrapperCol.span}>
              <Radio.Group
                value={fileStrategy}
                onChange={e => setFileStrategy(e.target.value)}
                style={{ width: '100%' }}
              >
                <Radio.Button value="upload" style={{ width: '50%' }}>
                  Upload a file
                </Radio.Button>
                <Radio.Button value="select" style={{ width: '50%' }}>
                  Select a file
                </Radio.Button>
              </Radio.Group>
            </Col>
          </Row>
          <Form.Item
            hidden={fileStrategy !== 'select'}
            label="File"
            name="file_name"
            rules={[
              {
                required: fileStrategy === 'select',
                message: 'field is required',
              },
            ]}
            {...formItemLayout}
          >
            <Select options={fileNameOptions} />
          </Form.Item>
          <Form.Item
            hidden={fileStrategy !== 'upload'}
            label="File"
            name="upload"
            required
            rules={[
              {
                validator: () => {
                  if (fileToUpload || fileStrategy !== 'upload')
                    return Promise.resolve()
                  return Promise.reject(new Error('upload is required'))
                },
              },
            ]}
            {...formItemLayout}
          >
            <style>{css`
              div.ant-upload {
                width: 100%;
              }
            `}</style>
            <Upload.Dragger
              name="firmware_file"
              maxCount={1}
              beforeUpload={beforeUpload}
              fileList={fileList}
              onRemove={() => setFileToUpload(null)}
              accept={VALID_CONTENT_TYPES.join(',')}
            >
              <UploadOutlined />
            </Upload.Dragger>
          </Form.Item>
        </Form>
      </Modal>
    </>
  )
}

export default CreateOTAFirmwareModal
