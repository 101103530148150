import { withRouter, RouteComponentProps } from 'react-router-dom'
import { gql, ApolloQueryResult } from '@apollo/client'
import { ColumnProps } from 'antd/lib/table'
import { Card, Icon } from '@weareberyl/design-system'

import { formatDatetime } from 'utils'
import { useQuery } from 'components/Query'
import { withScheme } from 'components/Scheme'
import { isPhone } from 'utils/mobileDetect'
import Table from 'components/Table'
import { useQueryParams } from 'utils/useQueryParams'
import TabTitle from 'components/shared/TabTitle'
import Tabs from 'components/shared/Tabs'
import { Tooltip } from 'antd'
import InvoiceStatusLabel from '../InvoiceStatus'
import { EmptyComponent } from '../../Query/utils'
import { formatInvoiceType } from '../formatters'
import { InvoiceStatus } from '../../../__generated__/globalTypes'
import type { Pagination } from 'types'
import type {
  INVOICES_LIST,
  INVOICES_LIST_table_nodes as Invoice,
} from './__generated__/INVOICES_LIST'
import * as routes from 'constants/routes'
import { WithScheme } from 'components/Scheme/withScheme'

const { Tab } = Tabs

const columns: ColumnProps<Invoice>[] = [
  {
    title: 'User',
    dataIndex: ['user', 'email'],
  },
  {
    title: 'Status',
    dataIndex: 'status',
    render: status => <InvoiceStatusLabel status={status} />,
  },
  {
    title: 'Date/Time',
    dataIndex: 'created_at',
    render: t => formatDatetime(t),
  },
  {
    title: 'Description',
    dataIndex: 'description',
    className: 'xs-hide',
  },
  {
    title: 'Total Currency',
    dataIndex: ['currency_debit', 'formatted_amount'],
  },
  {
    title: 'Total Minutes',
    dataIndex: ['minute_debit', 'formatted_amount'],
  },
  {
    title: 'Type',
    dataIndex: '__typename',
    render: type => formatInvoiceType(type),
  },
  {
    title: '',
    dataIndex: 'id',
    render: id => (
      <Tooltip title={id} placement="left">
        <Icon
          color="grape"
          height={12}
          resizeMode="contain"
          type="information"
          width={12}
          mt={1}
        />
      </Tooltip>
    ),
  },
]

type InvoiceTableProps = {
  onChange?: (arg0: Pagination) => void
  columns: ColumnProps<Invoice>[]
  onRow: (record: Invoice) => {
    onClick: () => void
  }
  props: ApolloQueryResult<INVOICES_LIST>
}

const InvoiceTable = ({
  columns,
  onRow,
  props,
  onChange,
}: InvoiceTableProps) => {
  const isEmptyResult = (props.data.table?.nodes || []).length === 0

  if (isEmptyResult) {
    return <EmptyComponent />
  }

  return (
    <Table
      id="invoices-table"
      onChange={onChange}
      columns={columns}
      onRow={onRow}
      {...props}
    />
  )
}

const INVOICES_LIST_QUERY = gql`
  query INVOICES_LIST(
    $scheme_id: ID
    $user_id: ID
    $paginate: Paginate
    $where: [IInvoiceWhere]
    $order_by: [IInvoiceOrderby]
    $is_mobile: Boolean!
  ) {
    table: invoices(
      scheme_id: $scheme_id
      user_id: $user_id
      where: $where
      paginate: $paginate
      order_by: $order_by
    ) {
      nodes {
        id
        created_at
        status
        description @skip(if: $is_mobile)
        user {
          id
          email
          deleted
        }
        minute_debit {
          formatted_amount
        }
        currency_debit {
          formatted_amount
        }
      }
      pagination {
        current: page
        pageSize: per_page
        total
      }
    }
  }
`

const status = {
  all: 'all',
  refunded: 'refunded',
  ...InvoiceStatus,
}

export type InvoiceListProps = {
  user_id?: string
}
type Props = WithScheme & RouteComponentProps & InvoiceListProps
const InvoiceList = ({ scheme, history, user_id }: Props) => {
  const [{ current, pageSize, currentTab }, setQueryParams] = useQueryParams(
    'invoice-table',
    {
      currentTab: status.all,
    },
  )

  const getTableBorderProps = () => {
    switch (currentTab) {
      case status.all:
        return { borderTopLeftRadius: 0 }
      case status.void:
        return { borderTopRightRadius: 0 }
      default:
        return {}
    }
  }

  const getQueryVars = () => {
    const queryVars = {
      scheme_id: scheme.id,
      user_id,
      order_by: [{ id: 'desc' }],
      is_mobile: isPhone(),
      paginate: {
        page: current,
        per_page: pageSize,
      },
      where: [] as object[],
    }

    if (currentTab !== status.all) {
      if (currentTab === status.refunded) {
        queryVars.where = [
          {
            status: {
              in: ['fully_refunded', 'partially_refunded'],
            },
          },
        ]
      } else {
        queryVars.where = [{ status: { eq: currentTab } }]
      }
    }

    return queryVars
  }

  const { Component: InvoiceListQuery } = useQuery({
    waitFor: 'data.table',
    pollInterval: 0,
    query: INVOICES_LIST_QUERY,
    variables: getQueryVars(),
  })

  return (
    <Card variant="gray" p={3}>
      <Tabs>
        <Tab
          onPress={() => setQueryParams({ currentTab: status.all, current: 1 })}
          isSelected={currentTab === status.all}
        >
          <TabTitle tabText="All" icon="burger" />
        </Tab>
        <Tab
          onPress={() =>
            setQueryParams({ currentTab: status.outstanding, current: 1 })
          }
          isSelected={currentTab === status.outstanding}
        >
          <TabTitle tabText="Outstanding" icon="warning" />
        </Tab>
        <Tab
          onPress={() =>
            setQueryParams({ currentTab: status.settled, current: 1 })
          }
          isSelected={currentTab === status.settled}
        >
          <TabTitle tabText="Settled" icon="tick" />
        </Tab>
        <Tab
          onPress={() =>
            setQueryParams({ currentTab: status.refunded, current: 1 })
          }
          isSelected={currentTab === status.refunded}
        >
          <TabTitle tabText="Refunded" icon="repeat" />
        </Tab>
        <Tab
          onPress={() =>
            setQueryParams({ currentTab: status.void, current: 1 })
          }
          isSelected={currentTab === status.void}
        >
          <TabTitle tabText="Void" icon="cross" />
        </Tab>
      </Tabs>
      <Card variant="borderless" {...getTableBorderProps()}>
        <InvoiceListQuery>
          {(props: ApolloQueryResult<INVOICES_LIST>) => (
            <InvoiceTable
              columns={columns}
              onChange={({ current, pageSize }) =>
                setQueryParams({ current, pageSize })
              }
              onRow={record => {
                return {
                  onClick: () => {
                    const invoicePath = routes.prependScheme(
                      `${routes.INVOICES}/${record.id}`,
                      scheme.id,
                    )
                    history.push(invoicePath)
                  },
                }
              }}
              props={props}
            />
          )}
        </InvoiceListQuery>
      </Card>
    </Card>
  )
}

export default withRouter(withScheme(InvoiceList))
