import {
  useSchemePermittedVehiclesLazyQuery,
  SchemePermittedVehiclesQuery_permitted_vehicle_models_PermittedVehicleModel,
} from 'gql/generated/graphql'

export default (): [
  (schemeId: string) => void,
  (
    | SchemePermittedVehiclesQuery_permitted_vehicle_models_PermittedVehicleModel[]
    | undefined
  ),
] => {
  const [getPermittedVehiclesQuery, { data }] =
    useSchemePermittedVehiclesLazyQuery()
  // Return a function that takes schemeId at call time
  const getBySchemeId = (schemeId: string) => {
    return getPermittedVehiclesQuery({ variables: { schemeId } })
  }

  return [getBySchemeId, data?.permitted_vehicle_models]
}
