import { useState } from 'react'
import { Menu, Modal } from 'antd'
import type { ApolloQueryResult } from '@apollo/client'

import Query from 'components/Query'
import { withUser } from 'components/Session'
import { withScheme } from 'components/Scheme'
import InvalidateUserLicence from 'components/User/actions/InvalidateUserLicence'
import RemoveUserPhoneNumber from 'components/User/actions/removeUserPhoneNumber'
import SetMemberRole from 'components/User/SetMemberRole'
import BlockUser from './BlockUser'
import ChargeExemptionModal from './ChargeExemptions'
import PhoneNumberModal from './PhoneNumberModal'
import {
  CustomerListDocument,
  StaffListDocument,
  UserActionsDocument,
  UserActionsQuery,
} from 'gql/generated/graphql'

enum Modals {
  Roles = 1,
  ChargeExemptions = 2,
  PhoneNumber = 3,
}

export const Actions = withScheme(
  withUser(({ user_id, scheme }) => {
    const [modal, setModal] = useState<Modals | null>(null)

    const onClose = () => {
      setModal(null)
    }

    return (
      <Query
        waitFor="data.user"
        query={UserActionsDocument}
        variables={{ user_id }}
        pollInterval={0}
      >
        {({ data }: ApolloQueryResult<UserActionsQuery>) => {
          return (
            <Menu id="user-actions-menu">
              <Modal
                open={modal === Modals.Roles}
                zIndex={1050}
                width={550}
                bodyStyle={{ padding: 0 }}
                onCancel={onClose}
                footer={null}
                destroyOnClose
              >
                {data.user && (
                  <SetMemberRole
                    members={[
                      {
                        email: data?.user?.email ?? '',
                        id: data?.user?.id,
                        isNewMember: false,
                        // @ts-ignore
                        roles: data?.user?.roles,
                      },
                    ]}
                    closeModal={onClose}
                    refetchQueries={[
                      {
                        query: StaffListDocument,
                        variables: { scheme_id: scheme.id },
                      },
                      {
                        query: CustomerListDocument,
                        variables: { scheme_id: scheme.id },
                      },
                    ]}
                  />
                )}
              </Modal>
              <Menu.Item onClick={() => setModal(Modals.Roles)}>
                Add/Remove Roles
              </Menu.Item>

              <ChargeExemptionModal
                close={onClose}
                userId={user_id}
                schemeId={scheme.id}
                visible={modal === Modals.ChargeExemptions}
              />

              <Menu.Item onClick={() => setModal(Modals.ChargeExemptions)}>
                Set charge exemption
              </Menu.Item>

              <Menu.Item>
                <InvalidateUserLicence user_id={user_id} />
              </Menu.Item>

              {data?.user?.persona_enrolled && (
                <Menu.Item>
                  <BlockUser
                    user_id={user_id}
                    is_blocked={Boolean(data?.user?.is_blocked)}
                  />
                </Menu.Item>
              )}

              <Menu.Item disabled={!data.user?.phone_number}>
                <RemoveUserPhoneNumber user_id={user_id} />
              </Menu.Item>

              <PhoneNumberModal
                close={onClose}
                userId={user_id}
                visible={modal === Modals.PhoneNumber}
              />

              <Menu.Item
                disabled={Boolean(data.user?.phone_number)}
                onClick={() => setModal(Modals.PhoneNumber)}
              >
                Set phone number
              </Menu.Item>
            </Menu>
          )
        }}
      </Query>
    )
  }),
)
