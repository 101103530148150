import { useState } from 'react'
import { Menu, Typography } from 'antd'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import { Heading, Box, Button, Flex } from '@weareberyl/design-system'
import type { MenuItemType } from 'antd/lib/menu/hooks/useItems'

import { getDefaultRoute } from 'components/Navigation'
import * as routes from 'constants/routes'

import { Center } from 'components/Layout'
import { SCHEME_EMOJIS } from 'constants/domain'
import { useCurrentUser, useCurrentScheme } from 'hooks'

import useSchemes, { SchemePickerScheme } from './useSchemes'

// TODO remove this and import from elsewhere
import withScheme from './withScheme'
import useSchemeJobAssets from 'hooks/useSchemeJobAssets'
export { withScheme }

export const UpdateCurrentScheme = ({ scheme: { id, name } }) => {
  const { setSchemeIdAndUpdateLocation } = useCurrentScheme()
  const onClick = () => {
    setSchemeIdAndUpdateLocation(id)
  }

  const emoji = SCHEME_EMOJIS[id]

  return (
    <Flex onClick={onClick} style={{ flexWrap: 'nowrap' }}>
      <Typography.Text style={{ fontSize: 20, marginRight: '0.5ex' }}>
        {emoji}
      </Typography.Text>
      <Typography.Text ellipsis>{name}</Typography.Text>
    </Flex>
  )
}

const SchemeSelectionCard = withRouter(
  ({ id, name, history }: SchemePickerScheme & RouteComponentProps) => {
    const [currentUser] = useCurrentUser()
    const emoji = SCHEME_EMOJIS[id]

    return (
      <Button
        onPress={() => {
          if (!currentUser) {
            return
          }
          history.push(
            routes.prependScheme(getDefaultRoute(currentUser, id), id),
          )
        }}
        my={1}
        variant="none"
        bg="white"
        borderColor="oyster"
        activeBackgroundColor="oyster"
        activeBorderColor="afternoon"
        title={
          <>
            <Typography.Text style={{ fontSize: 20, marginRight: '0.5ex' }}>
              {emoji}
            </Typography.Text>
            <Typography.Text ellipsis>{name}</Typography.Text>
          </>
        }
      />
    )
  },
)

export const SchemeList = () => {
  const { selectableSchemes } = useSchemes()
  return (
    <Center containerStyle={{ maxWidth: 500 }}>
      <Heading variant="h1">Please select a scheme</Heading>
      <Box py={5}>
        {selectableSchemes.map(scheme => (
          <SchemeSelectionCard key={scheme.id} {...scheme} />
        ))}
      </Box>
    </Center>
  )
}

export const SchemeNav = ({
  mobile = false,
}: {
  // Render inline for mobile, vertical in sider
  mobile?: boolean
}) => {
  const { selectableSchemes, currentScheme } = useSchemes()
  const { setSchemeIdAndUpdateLocation } = useCurrentScheme()
  const [openKeys, setOpenKeys] = useState<string[]>([])

  // load assets into cache once scheme is set, for better performance when creating jobs
  useSchemeJobAssets(currentScheme?.id)

  const items = selectableSchemes.map((scheme): MenuItemType => {
    return {
      label: scheme.name,
      icon: (
        <span style={{ marginRight: '5px' }}>{SCHEME_EMOJIS[scheme.id]}</span>
      ),
      key: scheme.id,
    }
  })

  const onClick = ({ key }) => {
    setSchemeIdAndUpdateLocation(key)
    // Close the dropdown
    setOpenKeys([])
  }

  const activeKey = currentScheme?.id.toString()

  const schemeTitle = (
    <div className="scheme-menu-title">
      {currentScheme && (
        <span className="scheme-menu-title--icon">
          {SCHEME_EMOJIS[currentScheme.id]}
        </span>
      )}
      <span
        className="scheme-menu-title--text"
        data-testid="active-scheme-title"
      >
        {currentScheme ? currentScheme.name : '...'}
      </span>
    </div>
  )

  if (items.length > 0) {
    return (
      <Menu
        items={[
          {
            key: 'schemes',
            label: schemeTitle,
            children: items,
          },
        ]}
        openKeys={openKeys}
        onOpenChange={setOpenKeys}
        activeKey={activeKey}
        onClick={onClick}
        className="scheme-menu"
        mode={mobile ? 'inline' : 'vertical'}
        inlineIndent={0}
        selectable
      />
    )
  }
  return schemeTitle
}
