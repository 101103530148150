import React, { ReactNode } from 'react'
import { isEqual } from 'lodash'
import { Card, Heading, Flex } from '@weareberyl/design-system'

import { CreateJob } from 'components/Job/Actions'
import TabTitle from 'components/shared/TabTitle'
import Tabs from 'components/shared/Tabs'

import { useCurrentScheme, useCurrentUser, useJobTypes } from 'hooks'
import { useQueryParams } from 'utils/useQueryParams'
import { Scheme } from 'types'
import { parseJobFilters, JobFilters } from './JobFilters'
import JobsMap from './JobsMap'
import JobsTable from './JobsTable'
import { LoadingComponent } from 'components/Query/utils'
import useJobList from 'hooks/useJobList'
import { JobFiltersInput } from 'gql/generated/graphql'

const { Tab } = Tabs

const tabs = {
  map: 'map',
  table: 'table',
}

const JobListTabs = ({ setQueryParams, currentTab }) => {
  return (
    <Tabs>
      <Tab
        isSelected={currentTab !== tabs.map}
        onPress={() =>
          setQueryParams({
            currentTab: tabs.table,
            current: 1,
            status: null,
            pageSize: 20,
          })
        }
      >
        <TabTitle tabText="Table" icon="menu" />
      </Tab>
      <Tab
        isSelected={currentTab === tabs.map}
        onPress={() =>
          setQueryParams({
            currentTab: tabs.map,
            status: 'new',
            pageSize: 500,
          })
        }
      >
        <TabTitle tabText="Map" icon="scheme" />
      </Tab>
    </Tabs>
  )
}

interface ListProps {
  scheme?: Scheme
  filters?: Omit<JobFiltersInput, 'scheme_id'>
  showExtras?: boolean
  showMap?: boolean
  children?: ReactNode
  slim?: boolean
}

export const List = ({
  scheme,
  filters,
  showExtras = false,
  showMap = false,
  children,
  slim = false,
}: ListProps) => {
  const [
    {
      current,
      pageSize,
      assetTag,
      assetNameContains,
      assetCategory,
      assignee,
      role,
      status,
      taskId,
      currentTab,
    },
    setQueryParams,
  ] = useQueryParams('jobs-table', { ...filters })

  const [user] = useCurrentUser()
  const { currentSchemeId } = useCurrentScheme()
  const currentScheme = scheme ?? {
    ...(currentSchemeId && { id: currentSchemeId }),
  }

  const {
    data: table,
    refetch,
    loading,
  } = useJobList({
    variables: {
      job_filters: parseJobFilters({
        scheme_id: currentScheme.id ? [currentScheme.id] : null,
        assignee,
        assetTag,
        assetNameContains,
        role,
        status,
        taskId,
        assetCategory,
      }),
      paginate: {
        page: current,
        per_page: pageSize,
      },
    },
    pollInterval: 0,
  })

  const { jobOptionsByRole } = useJobTypes()

  const Content = () => (
    <>
      <Card variant="borderless" p={3} mt={slim ? 0 : 30}>
        <Flex justifyContent="space-between" alignItems="center" mb={5}>
          {slim ? (
            <Heading variant="h1">🛠️ Active</Heading>
          ) : (
            <Heading variant="callout">Jobs</Heading>
          )}
          <Flex justifyContent="end">
            <CreateJob
              scheme={currentScheme}
              assetTag={assetTag?.[0]}
              onSuccess={refetch}
            />
          </Flex>
        </Flex>
        <JobFilters
          jobOptionsByRole={jobOptionsByRole}
          setQueryParams={setQueryParams}
          assetNameContains={assetNameContains}
          taskId={taskId}
          assetCategory={assetCategory}
          assignee={assignee}
          role={role}
          status={status}
          hidden={!showExtras}
          loading={loading}
        />
        {showMap && (
          <JobListTabs
            setQueryParams={setQueryParams}
            currentTab={currentTab}
          />
        )}
        {currentTab !== tabs.map && (
          <JobsTable
            table={table}
            scheme={currentScheme}
            user={user}
            refetch={refetch}
          />
        )}
        {currentTab === tabs.map && showMap && (
          <>
            {!table && <LoadingComponent />}
            {table && (
              <JobsMap schemeId={currentScheme.id} nodes={table?.nodes} />
            )}
          </>
        )}
      </Card>
      {children && (
        <Card variant="gray" p={3} mt={30}>
          {children}
        </Card>
      )}
    </>
  )

  if (slim) {
    return <Content />
  } else {
    return (
      <Card p={5}>
        <Content />
      </Card>
    )
  }
}

const jobListPropEqualityCheck = (prevProps, newProps) => {
  // Checks that props has the same values as previous render
  return isEqual(prevProps, newProps)
}

const JobList = React.memo(List, jobListPropEqualityCheck)
export { JobList }

export default (props: ListProps) => <JobList {...props} showExtras />
