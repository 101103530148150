import React from 'react'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import {
  colors,
  Flex,
  Heading,
  Text,
  getVehicleName,
  isElectricVehicle,
  Box,
} from '@weareberyl/design-system'

import ModuleLink from 'components/ModuleLink'
import HardwareTypeIcon from './HardwareTypeIcon'
import * as routes from 'constants/routes'
import batteryIcon from 'images/battery.svg'

import { ModuleHardware } from '__generated__/globalTypes'
import { SCHEME } from 'types'
import { PermissionOnly } from 'components/Session'
import Dropdown from 'components/shared/Dropdown'
import { MenuOutlined } from '@ant-design/icons'
import { VehicleContextMenu } from 'components/Module/context'
import { LockConfiguration } from 'gql/generated/graphql'
import { MODULE_SUMMARY__DASHBOARD_module_tags } from 'components/Module/__generated__/MODULE_SUMMARY__DASHBOARD'
import Tags from 'components/Module/Tags'

interface IBatteryPercentage {
  batteryPercent: number | null
  hardwareType: ModuleHardware | null
  size?: 'large' | 'medium'
}

const BatteryPercentage = ({
  size,
  batteryPercent,
  hardwareType,
}: IBatteryPercentage) => {
  if (!hardwareType || !isElectricVehicle(hardwareType)) return null
  return (
    <Flex mt={size === 'large' ? 1 : 0} ml={2}>
      <img
        alt="electric"
        style={{ height: 18, marginTop: 2 }}
        src={batteryIcon}
      />
      <Text ml={1}>{batteryPercent || '- '}%</Text>
    </Flex>
  )
}

type ModuleHeaderProps = RouteComponentProps & {
  moduleId: string | null
  frameId?: string | null
  scheme?: SCHEME | null
  hardwareType: ModuleHardware | null
  vehicleId?: string
  batteryPercent: number | null
  size?: 'large' | 'medium'
  location: any
  lockConfiguration?: LockConfiguration
  tags?: MODULE_SUMMARY__DASHBOARD_module_tags[]
  scooterSpeedLimited?: boolean
}

const ModuleHeader = withRouter(
  ({
    moduleId,
    frameId,
    scheme,
    hardwareType,
    vehicleId,
    batteryPercent,
    size = 'large',
    location,
    lockConfiguration,
    tags,
    scooterSpeedLimited,
  }: ModuleHeaderProps) => {
    const URL = `${routes.MODULES}/${moduleId}`
    const summaryTitle = getModuleTitle(frameId, hardwareType)

    return (
      <Flex width="100%">
        <Flex flexDirection="row" width="100%">
          <Flex flex={4}>
            <HardwareTypeIcon
              hardwareType={hardwareType}
              mt={size === 'large' ? 2 : '5px'}
            />
            {location.pathname.includes(URL) || !moduleId ? (
              <Heading variant={size === 'large' ? 'h1' : 'h3'}>
                {summaryTitle}
              </Heading>
            ) : (
              <ModuleLink id={moduleId} bikeScheme={scheme ?? undefined}>
                <Heading
                  variant={size === 'large' ? 'h1' : 'h3'}
                  color={colors.jade}
                >
                  {summaryTitle}
                </Heading>
              </ModuleLink>
            )}
            <BatteryPercentage
              batteryPercent={batteryPercent}
              size={size}
              hardwareType={hardwareType}
            />
          </Flex>
          <Flex flexDirection="row" justifyContent="end" flex={1}>
            {moduleId && hardwareType && vehicleId && (
              <PermissionOnly permission="module.actions">
                <Dropdown
                  overlay={
                    <VehicleContextMenu
                      module_id={moduleId}
                      hardware_type={hardwareType}
                      vehicleId={vehicleId}
                      lock_configuration={lockConfiguration}
                    />
                  }
                >
                  <MenuOutlined
                    data-testid="actions-menu"
                    style={{ cursor: 'pointer' }}
                  />
                </Dropdown>
              </PermissionOnly>
            )}
          </Flex>
        </Flex>
        {tags && (
          <Box pt={2}>
            <Tags tags={tags} vehicleId={vehicleId} />
          </Box>
        )}
        {scooterSpeedLimited && (
          <Box pt={2}>
            <Text ml={1} fontFamily="Hellix-SemiBold" variant="small">
              ⚠️ Speed has been limited
            </Text>
          </Box>
        )}
      </Flex>
    )
  },
)

export default ModuleHeader

export const getModuleTitle = (
  frameId?: string | null,
  hardwareType?: ModuleHardware | null,
) => {
  if (frameId) {
    return `${
      hardwareType ? getVehicleName(hardwareType) : 'Vehicle'
    } ${frameId}`
  }

  return 'Unknown Frame ID'
}
