import moment, { Moment } from 'moment'
import { Alert, message } from 'antd'
import { useHistory } from 'react-router-dom'

import * as routes from 'constants/routes'
import { useCreatePromotion } from '../data'
import type {
  CreateEoJPromotionMutationVariables,
  GetCampaignQuery_campaign_EoJPromotion,
} from 'gql/generated/graphql'
import CombinedPromotionForm from '../form/CombinedForm'
import { FormValues } from '../form/PromotionForm'

const PromotionEdit = ({
  promotion,
}: {
  promotion: GetCampaignQuery_campaign_EoJPromotion
}) => {
  const history = useHistory()
  // TODO: Edit mutation
  const { saving, error } = useCreatePromotion()

  const onFinish = (_variables: CreateEoJPromotionMutationVariables) => {
    message.info("Editing isn't implemented yet, please use Voucherify")
    history.replace(routes.CAMPAIGNS)
    return Promise.resolve()
  }

  let promotion_dates: Moment[] | undefined

  if (promotion.start_date && promotion.expiration_date) {
    // NOTE: Using moment cos AntD form wants me to
    promotion_dates = [
      moment(promotion.start_date),
      moment(promotion.expiration_date),
    ]
  }

  const values: FormValues = {
    name: promotion.name,
    banner: promotion.banner,
    description: promotion.description,
    scheme_ids: promotion.scheme_ids ?? [],
    vehicle_categories: promotion.vehicle_categories,
    validation_rule_ids: promotion.validation_rule_ids,
    journey_first_free_minutes:
      promotion.journey_first_free_minutes ?? undefined,
    journey_percent_off_time_based_charges:
      promotion.journey_percent_off_time_based_charges ?? undefined,
    unlock_fee_percent_off: promotion.unlock_fee_percent_off ?? undefined,
    promotion_dates,
  }

  return (
    <>
      {error && (
        <Alert
          type="error"
          message="Oh dear"
          description={error.message}
          style={{ marginBottom: '20px' }}
          showIcon
        />
      )}

      <CombinedPromotionForm
        onFinish={onFinish}
        saving={saving}
        values={values}
      />
    </>
  )
}

export default PromotionEdit
