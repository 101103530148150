import { Component } from 'react'
import Query from 'components/Query'
import { gql, ApolloQueryResult } from '@apollo/client'
import { get } from 'lodash'
import { Icon as LegacyIcon } from '@ant-design/compatible'
import { InfoCircleFilled } from '@ant-design/icons'
import { Modal, Button } from 'antd'
import Timeline from '../Timeline'
import { formatDatetime } from 'utils'
import { CONNECTIVITY_ERROR_CODES } from './Constants'

import {
  MODULE_CONNECTIVITY_HISTORY,
  MODULE_CONNECTIVITY_HISTORYVariables,
  MODULE_CONNECTIVITY_HISTORY_module_connectivity_history_nodes_details as ConnectivityDetails,
} from '../Module/__generated__/MODULE_CONNECTIVITY_HISTORY'
import { Box } from '@weareberyl/design-system'

const MODULE_CONNECTIVITY_HISTORY_QUERY = gql`
  query MODULE_CONNECTIVITY_HISTORY($id: ID!) {
    module(module_id: $id) {
      id
      connectivity_history {
        nodes {
          id
          time
          is_online
          details {
            error_message
            error_code
            last_error_time
            last_heartbeat_time
            last_event_time
            last_state_time
            last_config_ack_time
            last_config_send_time
          }
        }
      }
    }
  }
`

type Props = MODULE_CONNECTIVITY_HISTORYVariables

type State = {
  modalIsVisible: boolean
  modalItem: object | null
}

export default class ConnectivityHistory extends Component<Props, State> {
  state = {
    modalIsVisible: false,
    modalItem: null,
  }

  _onCloseClick = () => this.setState({ modalIsVisible: false })

  _itemWrapper = (
    item: {
      time: string
      is_online: boolean
      details: ConnectivityDetails | null
    },
    i: number,
  ) => {
    const { time, is_online: isOnline } = item

    return (
      <div className="mb-1" key={i}>
        <LegacyIcon
          type="thunderbolt"
          theme={isOnline ? 'filled' : 'outlined'}
          style={{ fontSize: 16 }}
        />{' '}
        <span style={{ fontWeight: 600 }}>
          {isOnline ? 'Connected' : 'Disconnected'}
        </span>{' '}
        - {formatDatetime(time, 'time')}{' '}
        {!isOnline && item.details !== null && (
          <InfoCircleFilled
            style={{ fontSize: 16, cursor: 'pointer' }}
            onClick={() =>
              this.setState({ modalIsVisible: true, modalItem: item })
            }
          />
        )}
      </div>
    )
  }

  render() {
    const { id } = this.props
    const { modalIsVisible, modalItem } = this.state

    return (
      <Query
        waitFor="data.module.connectivity_history.nodes"
        query={MODULE_CONNECTIVITY_HISTORY_QUERY}
        variables={{ id }}
      >
        {({ data }: ApolloQueryResult<MODULE_CONNECTIVITY_HISTORY>) => (
          <Box style={{ maxHeight: '500px', overflowY: 'scroll' }}>
            <Timeline
              items={get(data, 'module.connectivity_history.nodes', [])}
            >
              {this._itemWrapper}
            </Timeline>
            <ConnectivityDetailsModal
              visible={modalIsVisible}
              onClose={this._onCloseClick}
              afterClose={() => this.setState({ modalItem: null })}
              detailsItem={modalItem}
            />
          </Box>
        )}
      </Query>
    )
  }
}

const ConnectivityDetailsModal = ({
  visible,
  onClose,
  afterClose,
  detailsItem,
}: {
  visible: boolean
  onClose: () => void
  afterClose: () => void
  detailsItem: null | {
    time: string
    details: object
  }
}) => {
  const details = detailsItem && detailsItem.details

  return (
    <Modal
      title={detailsItem && formatDatetime(detailsItem.time, 'datetime')}
      open={visible}
      centered
      onCancel={onClose}
      afterClose={afterClose}
      footer={[
        <Button key="close" onClick={onClose}>
          Close
        </Button>,
      ]}
    >
      {details && (
        <p>
          {Object.keys(details).map(o => {
            if (o === '__typename') {
              return null
            }

            if (o === 'error_code') {
              const code = details[o]
              return (
                <span key={o}>
                  {o}: {code} - {CONNECTIVITY_ERROR_CODES[code]}
                  <br />
                </span>
              )
            }

            return (
              <span key={o}>
                {o}:{' '}
                {o.endsWith('_time')
                  ? formatDatetime(details[o], 'datetime')
                  : details[o]}
                <br />
              </span>
            )
          })}
        </p>
      )}
    </Modal>
  )
}
