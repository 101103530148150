import { Scheme } from 'types'
import { JobList } from '.'
import { Col, Row } from 'antd'
import ArchiveJobListContainer from 'components/ArchiveJob/List'
import { Box } from '@weareberyl/design-system'

export interface IAssetJobs {
  assetTag: string
  scheme: Scheme
  slim?: boolean
}

const AssetJobs = ({ assetTag, scheme, slim = false }: IAssetJobs) => {
  return (
    <Row gutter={16}>
      <Col xs={24}>
        <JobList
          filters={{
            assetTag: [assetTag],
          }}
          scheme={scheme}
          slim={slim}
        >
          <Box mt={1}>
            <ArchiveJobListContainer assetTag={assetTag} />
          </Box>
        </JobList>
      </Col>
    </Row>
  )
}

export default AssetJobs
