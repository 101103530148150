import { useState } from 'react'
import type { ApolloQueryResult } from '@apollo/client'
import { Button, Radio, RadioChangeEvent, TableProps } from 'antd'
import { useHistory } from 'react-router-dom'
import {
  Card,
  Flex,
  Heading,
  Button as BerylButton,
} from '@weareberyl/design-system'

import {
  CustomerListQuery,
  StaffListQuery,
  UsersListQuery,
  type UsersListQuery_table_UserConnection_nodes_User,
} from 'gql/generated/graphql'
import HeadTitle from 'components/HeadTitle'
import Table from 'components/Table'
import { useQueryParams } from 'utils/useQueryParams'

import * as routes from 'constants/routes'
import { useUserList, useUserVariables } from './data'
import columns from './columns'
import UserSearch from './Search'
import NewMemberModal from '../NewMemberModal'
import { PermissionOnly } from 'components/Session'
import { EditSelectedUsers } from './BatchEdit'
import { useCurrentScheme } from 'hooks'

type Props = {
  allUsers?: boolean
}

const UserList = ({ allUsers = false }: Props) => {
  const { currentSchemeId } = useCurrentScheme()
  const [showNewMemberModal, setShowNewMemberModal] = useState(false)
  const history = useHistory()
  const [queryParams, setQueryParams] = useQueryParams('users-table')
  const variables = useUserVariables(allUsers)
  const defaultQueryType = allUsers ? 'users' : 'customers'
  const queryType = queryParams.queryType ?? defaultQueryType
  const selectedRowKeys = queryParams.selectedRowKeys ?? []

  const tableProps: ApolloQueryResult<
    UsersListQuery | StaffListQuery | CustomerListQuery
  > = useUserList({ variables, queryType })

  const onQueryTypeChange = (e: RadioChangeEvent) => {
    setQueryParams({
      queryType: e.target.value,
    })
  }

  const title = {
    users: 'All users',
    customers: 'Customers',
    staff: 'Staff',
  }[queryType]

  const navigateToAllUsers = () => {
    history.push(routes.ALL_USERS)
  }

  const navigateToSchemeUsers = () => {
    if (currentSchemeId) {
      history.push(routes.prependScheme(routes.USERS, currentSchemeId))
    }
  }

  const onSelectedRowsChange = (selectedRowKeys: string[]) => {
    setQueryParams({ selectedRowKeys }, true)
  }

  const clearSelected = () => {
    setQueryParams({ selectedRowKeys: [] }, true)
  }

  const rowSelection: TableProps<UsersListQuery_table_UserConnection_nodes_User>['rowSelection'] =
    {
      selectedRowKeys,
      onChange: onSelectedRowsChange,
      preserveSelectedRowKeys: true,
    }
  return (
    <>
      <HeadTitle pageTitle={title} />

      <Card p={5}>
        <Flex
          justifyContent="space-between"
          alignItems="flex-start"
          mb={5}
          flexWrap="nowrap"
        >
          <Flex style={{ gap: '20px' }} flexWrap="wrap" flex={1}>
            <Heading variant="callout">{title}</Heading>
            {allUsers ? (
              <Flex style={{ gap: '10px' }} alignContent="center" pt={3}>
                <Button onClick={navigateToSchemeUsers}>Scheme users</Button>
              </Flex>
            ) : (
              <Flex style={{ gap: '10px' }} alignContent="center" pt={3}>
                <Radio.Group onChange={onQueryTypeChange} value={queryType}>
                  <Radio.Button value="staff">Staff</Radio.Button>
                  <Radio.Button value="customers">Customers</Radio.Button>
                </Radio.Group>
                <PermissionOnly permission="user.all">
                  <Button onClick={navigateToAllUsers}>All users</Button>
                </PermissionOnly>
              </Flex>
            )}
          </Flex>
          <BerylButton
            title="Create User"
            onClick={() => setShowNewMemberModal(true)}
            mt={2}
          />
        </Flex>

        <NewMemberModal
          show={showNewMemberModal}
          close={() => setShowNewMemberModal(false)}
        />

        <UserSearch loading={tableProps.loading} />
        <Table
          id="users-table"
          size="small"
          columns={columns}
          onChange={({ current, pageSize }) =>
            setQueryParams({ current, pageSize })
          }
          rowSelection={rowSelection}
          title={
            selectedRowKeys.length
              ? () => (
                  <EditSelectedUsers
                    selectedRowKeys={selectedRowKeys}
                    clear={clearSelected}
                  />
                )
              : undefined
          }
          {...tableProps}
        />
      </Card>
    </>
  )
}

export default UserList
