import { message } from 'antd'
import { Flex, Button, Text } from '@weareberyl/design-system'

import client from 'client'
import { useBulkZoneStatusMutation } from 'components/Zone/useBulkZoneStatusMutation'
import { useBulkZonePromotionalMutation } from 'components/Zone/useBulkZonePromotionalMutation'
import {
  type ZoneBulkEditablePropsFragment,
  ZoneBulkEditablePropsFragmentDoc,
} from 'gql/generated/graphql'
import { isNotNullOrUndefined } from 'utils/types'

import type { TabOptions } from '.'

type Props = {
  selectedRowKeys: string[]
  resetProperties: (currentTab: TabOptions, ids: string[]) => void
  currentTab: TabOptions
}

const EditSelectedZones = ({
  selectedRowKeys,
  resetProperties,
  currentTab,
}: Props) => {
  const viewingSuspended = currentTab === 'suspended'

  const [updateStatus, statusLoading] = useBulkZoneStatusMutation({
    suspend: viewingSuspended,
    zoneIds: selectedRowKeys,
    onCompleted: (failedIds: string[]) => {
      if (failedIds.length === 1) {
        message.error(
          `Failed to update the status for bay with ID: '${failedIds[0]}'`,
        )
      } else if (failedIds.length !== 0) {
        message.error('Failed to update the status of multiple bays')
      } else {
        message.success('Bay statuses successfully updated')
      }

      resetProperties(currentTab, failedIds)
    },
    onError: (err: Error) => message.error(err.message),
  })

  const [updatePromotional, promotionalLoading] =
    useBulkZonePromotionalMutation({
      zoneIds: selectedRowKeys,
      onCompleted: (failedIds: string[]) => {
        if (failedIds.length === 1) {
          message.error(
            `Failed to update the promotional status for bay with ID: '${failedIds[0]}'`,
          )
        } else if (failedIds.length !== 0) {
          message.error('Failed to update the status of multiple bays')
        } else {
          message.success('Bay statuses successfully updated')
        }

        resetProperties(currentTab, failedIds)
      },
      onError: (err: Error) => message.error(err.message),
    })

  // We have the zone IDs that are selected, but get from the Apollo cache
  // the promotional and suspended values to see what type of zones are selected
  const selectedZones: ZoneBulkEditablePropsFragment[] =
    selectedRowKeys
      .map(id =>
        client.readFragment({
          id: `Zone:${id}`,
          fragment: ZoneBulkEditablePropsFragmentDoc,
        }),
      )
      .filter(isNotNullOrUndefined) ?? []

  const promotionalSelected = selectedZones.filter(
    z => !z.is_promotional,
  ).length
  const notPromotionalSelected = selectedZones.filter(
    z => z.is_promotional,
  ).length
  const showSetPromotional = promotionalSelected > 0
  const showUnSetPromotional = notPromotionalSelected > 0
  const highlightPromotional = promotionalSelected > notPromotionalSelected

  return (
    <Flex mt={2} gridGap="10" alignItems="baseline" justifyContent="flex-start">
      {selectedRowKeys.length > 0 && (
        <>
          <Text>Update {selectedRowKeys.length} selected bays:</Text>
          <Button
            title={viewingSuspended ? 'Enable' : 'Suspend'}
            onPress={updateStatus}
            loading={statusLoading}
            variant={viewingSuspended ? 'primary' : 'danger'}
            size="small"
            px={3}
            py={2}
          />

          {showSetPromotional && (
            <Button
              title="Set promotional"
              onPress={() => updatePromotional(true)}
              loading={promotionalLoading}
              variant={highlightPromotional ? 'primary' : 'outline'}
              size="small"
              px={3}
              py={2}
            />
          )}

          {showUnSetPromotional && (
            <Button
              title="Set not promotional"
              onPress={() => updatePromotional(false)}
              loading={promotionalLoading}
              variant={highlightPromotional ? 'outline' : 'primary'}
              size="small"
              px={3}
              py={2}
            />
          )}
        </>
      )}

      <Flex flex={1} justifyContent="flex-end">
        <Button
          title="Clear selection"
          onPress={resetProperties}
          loading={statusLoading || promotionalLoading}
          variant="outline"
          size="small"
          px={3}
          py={2}
          disabled={selectedRowKeys.length === 0}
        />
      </Flex>
    </Flex>
  )
}

export default EditSelectedZones
