import {
  CustomerListQueryVariables,
  StaffListQueryVariables,
  UserWhere,
  UsersListQuery_Query,
  useCustomerListQuery,
  useStaffListQuery,
  useUsersListQuery,
  type UsersListQueryVariables,
} from 'gql/generated/graphql'
import { useCurrentScheme } from 'hooks'
import { useQueryParams } from 'utils/useQueryParams'

// Define a skeleton of data so the table can be shown
// with the loading state if there isn't any data yet
const defaultData: UsersListQuery_Query = {
  __typename: 'Query',
  table: {
    __typename: 'UserConnection',
    nodes: [],
    pagination: {
      __typename: 'Pagination',
      total: 0,
      current: 0,
      pageSize: 0,
    },
  },
}

export enum FilterType {
  email = 'email',
  phone_number = 'phone_number',
}

export type UserQueryType = 'users' | 'customers' | 'staff'

// All users means schemeID isn't mandatory
// Staff and customers have the same variables.
type UseUserVariablesReturn<T extends boolean> = T extends true
  ? UsersListQueryVariables
  : StaffListQueryVariables | CustomerListQueryVariables

export const useUserVariables = <T extends boolean = false>(
  allUsers: T = false as T,
): UseUserVariablesReturn<T> => {
  const [params] = useQueryParams('users-table')
  const { current, pageSize, search, filterType } = params
  const { currentSchemeId } = useCurrentScheme()

  const sanitisedFilterType =
    filterType in FilterType ? filterType : FilterType.email

  // Pass empty list of filters if no search value
  // Create a where clause with which filterType is selected
  const where: UserWhere[] = search
    ? [{ [sanitisedFilterType]: { like: `%${search}%` } }]
    : []

  const schemeId = allUsers ? undefined : currentSchemeId
  const variables = {
    schemeId,
    where,
    paginate: {
      page: current,
      per_page: pageSize,
    },
  }

  return variables as UseUserVariablesReturn<T>
}

type UseUserList =
  | {
      variables: UsersListQueryVariables
      queryType: 'users'
    }
  | {
      variables: CustomerListQueryVariables
      queryType: 'customers'
    }
  | {
      variables: StaffListQueryVariables
      queryType: 'staff'
    }

export const useUserList = ({ variables, queryType }: UseUserList) => {
  // Query users with a different query depending on which queryType is set
  // UsersListQuery does not require schemeId however the other 2 do
  const userList = useUsersListQuery(
    queryType === 'users'
      ? {
          variables,
          pollInterval: 0,
          // For some roles requesting, the 'roles' field will return an error due to permissions on the backend
          // but we still want to return the partial result with the remaining fields
          errorPolicy: 'all',
        }
      : {
          skip: true,
        },
  )
  const staffList = useStaffListQuery(
    queryType === 'staff'
      ? {
          variables,
          pollInterval: 0,
        }
      : {
          skip: true,
        },
  )
  const customerList = useCustomerListQuery(
    queryType === 'customers'
      ? {
          variables,
          pollInterval: 0,
        }
      : {
          skip: true,
        },
  )

  // Return the query data for the active UserQueryType
  const { data, ...rest } = {
    users: userList,
    staff: staffList,
    customers: customerList,
  }[queryType]

  const tableData = data ?? defaultData
  return { data: tableData, ...rest }
}
