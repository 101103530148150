import { gql, useQuery } from '@apollo/client'
import { Box, EcuError, Text } from '@weareberyl/design-system'
import { Popover, Card, message, Divider } from 'antd'

import ModuleHeader from 'components/ModuleHeader'
import CardSection, { SectionItem } from 'components/shared/CardSection'
import { capitalize, formatDatetime } from 'utils'

import { AssistConfig } from '__generated__/globalTypes'
import { REWARD_TYPES } from 'constants/domain'
import type { MODULE_SUMMARY__DASHBOARDVariables } from './__generated__/MODULE_SUMMARY__DASHBOARD'
import { startCase } from 'lodash'
import { getDerivedHardwareType } from 'utils/derived'
import { ModuleHardware } from 'gql/generated/graphql'

export const assistConfig = {
  [AssistConfig.ASSIST_CONFIG_NORMAL]: 'Normal',
  [AssistConfig.ASSIST_CONFIG_DISABLE]: 'Disabled',
  [AssistConfig.ASSIST_CONFIG_OVERRIDE]: 'Override',
}

const buildCards = module => {
  const hardwareType = module?.hardware_type
  const frameType = module?.vehicle?.frame_type
  const frameModel = module?.vehicle?.frame_model
  const vehicleId = module?.vehicle?.id
  const vehicleState = module?.vehicle?.state
  const dockState = module?.dock_state
  const lockState = module?.lock_state
  const scheme = module?.vehicle?.detail?.scheme
  const lockConfig = module?.vehicle?.detail?.lock_configuration
  const rewards = module?.vehicle?.rewards
  const showRewards = scheme?.config?.is_vehicle_rewards_enabled
  const moduleState = module?.module_state

  const configurationItems: Array<SectionItem> = [
    { key: 'Module ID', value: module.id },
  ]

  const hardwareStateItems: Array<SectionItem> = [
    {
      key: 'Last location',
      value: formatDatetime(module?.location?.time),
    },
    {
      key: 'Module charge',
      value: module?.telemetry?.battery_percent
        ? `${module?.telemetry?.battery_percent}%`
        : '',
    },
  ]

  const virtualStateItems: Array<SectionItem> = [
    {
      key: 'Last state change',
      value: formatDatetime(lockState?.time),
    },
    {
      key: 'Availability',
      value: capitalize(module?.availability?.status?.toString()),
    },
    {
      key: 'Vehicle state',
      value: capitalize(vehicleState?.state),
    },
  ]

  if (vehicleId) {
    configurationItems.push({
      key: 'Vehicle ID',
      value: vehicleId,
    })
  }

  if (frameType) {
    configurationItems.push({
      key: 'Frame type',
      value: startCase(frameType),
    })
  }

  if (frameModel) {
    configurationItems.push({
      key: 'Frame model',
      value: startCase(frameModel),
    })
  }

  if (scheme) {
    configurationItems.push({
      key: 'Lock Configuration',
      value: lockConfig,
    })
  }

  if (moduleState?.__typename === 'ModuleState') {
    // ModuleState
    if (hardwareType === ModuleHardware.bbe) {
      hardwareStateItems.push(
        {
          key: 'Assist config',
          value: moduleState?.assist_config
            ? assistConfig[moduleState?.assist_config]
            : 'No data',
        },
        {
          key: 'Drive ID',
          value: moduleState?.drive_id,
        },
        {
          key: 'Battery ID',
          value: moduleState?.battery_id,
        },
      )
    }
    configurationItems.push({
      key: 'Firmware version',
      value: moduleState?.firmware_version,
    })
    hardwareStateItems.push(
      {
        key: 'Power mode',
        value: moduleState?.power_mode,
      },
      {
        key: 'Orientation',
        value: moduleState?.orientation
          ? capitalize(moduleState?.orientation)
          : '...',
      },
      {
        key: 'Dock RFID tag',
        value: moduleState?.dock_nfc_tag_id,
      },
    )
  }

  if (moduleState?.__typename === 'ScooterModuleState') {
    // its ScooterModuleState
    hardwareStateItems.push(
      {
        key: 'Battery ID',
        value: moduleState?.battery_id,
      },
      {
        key: 'Max speed',
        value: moduleState?.max_speed?.toString(),
      },
    )
    if (moduleState?.ecu_error) {
      const ecu_error = module.module_state.ecu_error
      const { code, cause, solution, protection } = ecu_error
      hardwareStateItems.push({
        key: 'Error Code',
        value: (
          <Popover
            trigger="hover"
            placement="right"
            mouseEnterDelay={0.05}
            mouseLeaveDelay={0.05}
            content={
              <EcuError
                {...ecu_error}
                code={code ?? '...'}
                cause={cause ?? undefined}
                solution={solution ?? undefined}
                protection={protection ?? undefined}
              />
            }
          >
            <Text color="brick">{module.module_state.ecu_error.code}</Text>
          </Popover>
        ),
      })
    }
  }

  hardwareStateItems.push({
    key: 'Lock state',
    value: capitalize(
      lockState?.vehicle_lock_state ?? lockState?.overall_state,
    ),
  })

  if (dockState) {
    hardwareStateItems.push({
      key: 'Dock state',
      value: capitalize(dockState.message),
    })
  }

  if (module?.connectivity) {
    hardwareStateItems.push({
      key: 'Connectivity',
      value: module?.connectivity?.is_online ? 'Online' : 'Offline',
    })
  }

  if (moduleState?.orientation) {
    hardwareStateItems.push({
      key: 'Orientation',
      value: capitalize(moduleState?.orientation),
    })
  }

  if (showRewards) {
    virtualStateItems.push({
      key: 'Rewards available',
      value: (
        <>
          {rewards && rewards.length > 0
            ? rewards.map(reward => (
                <span key={reward.type}>⭐️ {REWARD_TYPES[reward.type]}</span>
              ))
            : 'None'}
        </>
      ),
    })
  }

  return [configurationItems, hardwareStateItems, virtualStateItems]
}

const MODULE_SUMMARY_QUERY = gql`
  query MODULE_SUMMARY__DASHBOARD($id: ID!) {
    module(module_id: $id) {
      id
      hardware_type
      vehicle {
        id
        frame_id
        frame_type
        frame_model
        detail {
          id
          scheme {
            id
            config {
              is_vehicle_rewards_enabled
            }
          }
          lock_configuration
        }
        state {
          id
          state
        }
        rewards {
          type
        }
      }
      module_state {
        id
        ... on ModuleState {
          firmware_version
          power_mode
          drive_id
          battery_id
          orientation
          dock_nfc_tag_id
          assist_config
        }
        ... on ScooterModuleState {
          battery_id
          max_speed
          ecu_error {
            id
            code
            name
            cause
            solution
            protection
          }
          orientation
        }
      }
      lock_state {
        id
        time
        overall_state
        vehicle_lock_state
      }
      dock_state {
        id
        time
        message
      }
      availability {
        id
        status
      }
      connectivity {
        is_online
      }
      location {
        id
        time
      }
      telemetry {
        id
        battery_percent
        drive {
          battery_percent
        }
      }
      tags {
        id
        name
        created_at
      }
    }
  }
`

export const ModuleSummary = ({ id }: MODULE_SUMMARY__DASHBOARDVariables) => {
  const { data, loading, error } = useQuery(MODULE_SUMMARY_QUERY, {
    variables: { id },
  })

  if (loading) {
    return null
  }

  if (error) {
    message.error(error.message)
    return null
  }

  if (!data?.module) {
    return null
  }

  const { module } = data
  const vehicleId = module?.vehicle?.id
  const frameId = module?.vehicle?.frame_id
  const scheme = module?.vehicle?.detail?.scheme
  const lockConfiguration = module?.vehicle?.detail?.lock_configuration

  const [configurationItems, hardwareStateItems, virtualStateItems] =
    buildCards(module)

  const maxSpeed =
    module?.module_state?.__typename === 'ScooterModuleState'
      ? module?.module_state?.max_speed
      : null
  const scooterSpeedLimited = maxSpeed && maxSpeed < 20

  return (
    <Box width="100%">
      <Card
        data-testid="module-summary-card"
        title={
          <ModuleHeader
            moduleId={module.id}
            frameId={frameId}
            scheme={scheme}
            hardwareType={getDerivedHardwareType(module)}
            batteryPercent={module?.telemetry?.drive?.battery_percent}
            vehicleId={vehicleId}
            tags={module?.tags}
            scooterSpeedLimited={scooterSpeedLimited}
            lockConfiguration={lockConfiguration}
          />
        }
      >
        <CardSection items={configurationItems} />
        <Divider />
        <CardSection items={hardwareStateItems} />
        <Divider />
        <CardSection items={virtualStateItems} />
      </Card>
    </Box>
  )
}

export default ModuleSummary
