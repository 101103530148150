import { useOTAListBucketFirmwaresQuery } from 'gql/generated/graphql'
import useWrappedQuery from 'hooks/useWrappedQuery'

const useOTABucketFirmwaresList = () =>
  useWrappedQuery(
    useOTAListBucketFirmwaresQuery({ pollInterval: 0 }),
    data => data?.list_ota_bucket_firmwares,
  )

export default useOTABucketFirmwaresList
