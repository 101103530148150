import CompleteJob from './CompleteJob'
import {
  Card,
  Box,
  Heading,
  Flex,
  JobHeader,
  TextLink,
  Text,
} from '@weareberyl/design-system'
import { JobList } from 'components/Job/List'
import { Link } from 'react-router-dom'
import TaskList from './Task'
import { formatDatetime } from 'utils'
import UserLink from 'components/UserLink'
import { useJobTypes } from 'hooks'
import JobsAssigner from './JobsAssigner/JobsAssigner'
import { AssetText, assetTagToZoneId } from './utils'
import { TEXT_LINK_VARIANTS } from '@weareberyl/design-system/build/components/Typography/TextLink'
import {
  AssetCategory,
  JobListQuery_table_JobConnection_nodes_Job_details_JobDetails_createdBy_User,
  JobQuery_job_Job,
} from 'gql/generated/graphql'
import useJob from 'hooks/useJob'
import HeadTitle from 'components/HeadTitle'

export const SEARCH_JOB = 'RD05'
export const MAX_SEARCH_JOB_ATTEMPTS = 3

interface IAssetLink {
  schemeId?: string | null
  assetTag?: string | null
  assetName?: string | null
  assetCategory: AssetCategory | null
  moduleId?: string | null
}

const AssetLink = ({
  schemeId,
  assetTag,
  assetName,
  assetCategory,
  moduleId,
}: IAssetLink) => {
  if (!assetTag) return null

  let url = ''

  switch (assetCategory) {
    case AssetCategory.bay:
      url = `/scheme/${schemeId}/zone/${assetTagToZoneId(assetTag)}`
      break
    case AssetCategory.bike:
    case AssetCategory.scooter:
    case AssetCategory.ebike:
    default:
      if (moduleId) {
        url = `/scheme/${schemeId}/bike/${moduleId}`
      } else {
        url = `/scheme/${schemeId}/bike?current=1&filterType=frame_id&id=module-table&pageSize=20&searchValue=${assetTag}`
      }
      break
  }

  return (
    <TextLink>
      <Link to={url}>
        <AssetText
          assetName={assetName}
          assetCategory={assetCategory}
          color={TEXT_LINK_VARIANTS.default.color}
        />
      </Link>
    </TextLink>
  )
}

const AssetHistory = ({ job }) => {
  const { assetTag, assetName, assetCategory, scheme } = job?.details ?? {
    assetTag: null,
    assetName: null,
    assetCategory: null,
    scheme: null,
  }
  return (
    <Box>
      <Flex alignItems="center">
        <Heading my={20} mr={10}>
          Asset History
        </Heading>
        <Box>
          <AssetLink
            assetTag={assetTag}
            assetName={assetName}
            assetCategory={assetCategory}
            moduleId={job.bike?.module?.id}
            schemeId={scheme?.id}
          />
        </Box>
      </Flex>
      {assetTag && <JobList filters={{ assetTag: [assetTag] }} />}
    </Box>
  )
}

const JobTimestamps = ({ job }: { job?: JobQuery_job_Job | null }) => {
  interface Props {
    heading: string
    timestamp: string
    user?: JobListQuery_table_JobConnection_nodes_Job_details_JobDetails_createdBy_User | null
  }

  const Row = ({ heading, timestamp, user }: Props) => (
    <tr>
      <td>
        <Text variant="small">{`${heading}:`}</Text>
      </td>
      <td>
        {user && (
          <>
            <Text variant="small" fontFamily="Hellix-SemiBold">
              {`${formatDatetime(timestamp)} by `}
            </Text>
            <Text variant="small" fontFamily="Hellix-SemiBold">
              <UserLink user={user}>{user.email}</UserLink>
            </Text>
          </>
        )}
      </td>
    </tr>
  )
  return (
    <Box maxWidth="350px" my={10}>
      <table>
        <tbody>
          <Row
            heading="Raised"
            timestamp={job?.timestamps?.created_at}
            user={job?.details.createdBy}
          />
          <Row
            heading="Updated"
            timestamp={job?.timestamps?.updated_at}
            user={job?.details.updatedBy}
          />
          <Row
            heading="Completed"
            timestamp={job?.timestamps?.completed_at}
            user={job?.details.completedBy}
          />
        </tbody>
      </table>
    </Box>
  )
}

const Job = ({ id }) => {
  const { jobCodeLookup } = useJobTypes()
  const { data: job } = useJob(id)

  if (!job?.details) {
    return null
  }
  const { assetTag, assetName, assetCategory, scheme, isUnlocked } = job.details
  const [attempts, maxAttempts] =
    job.details.taskId === SEARCH_JOB
      ? [job.attempts?.length, MAX_SEARCH_JOB_ATTEMPTS]
      : [undefined, undefined]
  const { taskId, ...jobDetails } = job.details

  return (
    <>
      <HeadTitle pageTitle={`Job ${id}`} />
      <Card mb={10}>
        <JobHeader
          role={jobDetails.role ?? null}
          priority={jobDetails.priority ?? null}
          scheme={jobDetails.scheme ?? null}
          status={String(jobDetails.status)}
          jobName={jobCodeLookup?.[taskId]}
          isUnlocked={isUnlocked ?? false}
          attempts={attempts}
          maxAttempts={maxAttempts}
        />
        <JobsAssigner jobs={[job]} mt={2} />
        <Flex>
          <Box my={10}>
            <AssetLink
              assetTag={assetTag}
              assetName={assetName}
              assetCategory={assetCategory ?? AssetCategory.unknown}
              moduleId={job.vehicle?.detail?.module?.id}
              schemeId={scheme?.id ?? null}
            />
          </Box>
        </Flex>
        <JobTimestamps job={job} />
        <Box>
          <CompleteJob job={job} />
        </Box>
      </Card>
      <Card mb={10}>
        <Box>
          <Heading my={20}>Tasks</Heading>
          <TaskList job={job} />
        </Box>
      </Card>
      <Card mb={10}>
        <AssetHistory job={job} />
      </Card>
    </>
  )
}

export default Job
