import React, { useState } from 'react'
import {
  Flex,
  Box,
  Text,
  Icon as BerylIcon,
  colors,
  Divider,
  Button,
  Heading,
} from '@weareberyl/design-system'
import { gql, useMutation } from '@apollo/client'
import { IdcardFilled } from '@ant-design/icons'
import { message, Input } from 'antd'
import { UPDATE_TASK, UPDATE_TASKVariables } from './__generated__/UPDATE_TASK'
import Status from 'components/Job/Status'
import Tag from '../../shared/Tag'
import {
  TaskCodeReason,
  TaskStatusEnum,
} from '../../../__generated__/globalTypes'
import TaskCodeSearch from './task-code-search'
import { isNotNullOrUndefined } from 'utils/types'
import {
  JobDocument,
  JobQuery_job_Job,
  JobQuery_job_Job_tasks_Task,
  ModuleHardware,
} from 'gql/generated/graphql'

export const UPDATE_TASK_MUTATION = gql`
  mutation UPDATE_TASK($job_id: ID!, $task: JobTaskInput!) {
    update_task(job_id: $job_id, task: $task)
  }
`

const Task = ({
  jobId,
  hardwareType,
  task,
}: {
  jobId: string
  hardwareType: ModuleHardware
  task: JobQuery_job_Job_tasks_Task
}) => {
  const [updateTask] = useMutation<UPDATE_TASK, UPDATE_TASKVariables>(
    UPDATE_TASK_MUTATION,
    {
      refetchQueries: [{ query: JobDocument, variables: { job_id: jobId } }],
    },
  )

  const { notes, assignee, description, status, task_codes_details } =
    task.details ?? {
      notes: '',
      assignee: null,
      description: '',
      status: TaskStatusEnum.new,
      task_codes_details: null,
    }

  const [notesInput, updateNotesInput] = useState(notes)
  const [taskCodes, setTaskCodes] = useState([
    ...(task_codes_details || []).map(details => details.code),
  ])

  function onOptionRemoved(key: string) {
    setTaskCodes(taskCodes.filter(item => item !== key))
  }

  function onSelectTaskCode(key: string) {
    setTaskCodes([...taskCodes, key])
  }

  return (
    <Box>
      <Flex>
        <Box mr={10}>{status && <Status status={status} />}</Box>
        <Box mr={10}>
          <Text fontFamily="Hellix-SemiBold" numberOfLines={2} mb={2}>
            {description}
          </Text>
        </Box>
      </Flex>
      <Box>
        <Box style={{ zIndex: 3 }}>
          <Heading my={2}>Task codes</Heading>
          <TaskCodeSearch
            onSelect={onSelectTaskCode}
            hardwareType={hardwareType}
          />
          {taskCodes && (
            <Flex>
              {taskCodes.map(item => (
                <Tag
                  key={item}
                  closable
                  onClose={() => onOptionRemoved(item)}
                  label={item}
                  labelVariant="regular"
                />
              ))}
            </Flex>
          )}
        </Box>
        <Box mt={1} mb={2}>
          <Heading my={2}>Notes</Heading>
          <Input.TextArea
            disabled={status === 'complete'}
            defaultValue={notesInput ?? ''}
            onChange={e => updateNotesInput(e.currentTarget.value.trim())}
          />
        </Box>
        <Flex justifyContent="space-between" alignItems="center">
          <Flex>
            <Box mt={1} mr={2}>
              <IdcardFilled
                style={{
                  fontSize: '15px',
                  color: colors.afternoon,
                }}
              />
            </Box>
            <Text numberOfLines={1} variant="small">
              {` ${assignee ? assignee.email : 'Unassigned'}`}
            </Text>
          </Flex>
          <Flex>
            <Box mr={10}>
              <Button
                disabled={status === TaskStatusEnum.complete}
                title="Submit Codes & Notes"
                onPress={async () => {
                  try {
                    await updateTask({
                      variables: {
                        job_id: jobId,
                        task: {
                          ...task,
                          details: {
                            ...task.details,
                            notes: notesInput,
                            assignee: assignee?.id,
                            task_codes_details: taskCodes.map(code => ({
                              code,
                              // NOTE: this will always update every code with 'other' reason even
                              // if it was different, but currently that's the only one we use...
                              reason: TaskCodeReason.other,
                            })),
                          },
                        },
                      },
                    })
                    message.success('Task notes have been saved')
                  } catch (err) {
                    message.error(err.message)
                  }
                }}
              />
            </Box>
            <Box mr={10}>
              <Button
                title="Complete Task"
                disabled={status === TaskStatusEnum.complete}
                onPress={async () => {
                  try {
                    await updateTask({
                      variables: {
                        job_id: jobId,
                        task: {
                          ...task,
                          details: {
                            ...task.details,
                            notes: notesInput,
                            assignee: assignee?.id,
                            status: TaskStatusEnum.complete,
                          },
                        },
                      },
                    })
                    message.success('Task status is now complete')
                  } catch (err) {
                    message.error(err.message)
                  }
                }}
              />
            </Box>
            <Box>
              <Button
                variant={'outline'}
                title="Cannot Complete Task"
                disabled={status === TaskStatusEnum.blocked}
                onPress={async () => {
                  try {
                    await updateTask({
                      variables: {
                        job_id: jobId,
                        task: {
                          ...task,
                          details: {
                            ...task.details,
                            notes: notesInput,
                            assignee: assignee?.id,
                            status: TaskStatusEnum.blocked,
                          },
                        },
                      },
                    })
                    message.success('Task status is now blocked')
                  } catch (err) {
                    message.error(err.message)
                  }
                }}
              />
            </Box>
          </Flex>
        </Flex>
      </Box>
      <Box alignItems="center" justifyContent="center" ml={2}>
        <BerylIcon name="ios-arrow-forward" size={24} />
      </Box>
      <Divider />
    </Box>
  )
}

const Tasks = ({ job }: { job: JobQuery_job_Job }) => {
  if (!job) {
    return null
  }
  const { tasks } = job
  const nonNullTasks = tasks?.filter(isNotNullOrUndefined) ?? []
  return (
    <Flex px={3} py={3} minHeight={80} flexDirection="column">
      {nonNullTasks.map(task => (
        <Task
          key={task.uuid}
          jobId={job.uuid}
          task={task}
          hardwareType={
            job.vehicle?.detail?.module?.hardware_type ??
            ModuleHardware.beryl_bike
          }
        />
      ))}
    </Flex>
  )
}

export default Tasks
