import { Icon as LegacyIcon } from '@ant-design/compatible'
import { Dropdown } from 'antd'
import { ColumnsType } from 'antd/lib/table'

import { Box } from '@weareberyl/design-system'

import * as routes from 'constants/routes'

import { Actions } from 'components/Job/Actions'
import AssetLink from 'components/Scheme/AssetLink'

import { formatDatetime, iconByIsAutomated } from 'utils'
import { AssetIcon } from '../utils'
import {
  AssetCategory,
  JobListQuery_table_JobConnection_nodes_Job as Job,
  JobListQuery,
  JobListQueryVariables,
  JobListQuery_table_JobConnection_nodes_Job,
  VehicleType,
} from 'gql/generated/graphql'
import { Scheme } from 'types'
import { RefetchFunction } from '@apollo/client/react/hooks/useSuspenseQuery'
import { round } from 'lodash'
import { useJobTypes } from 'hooks'

export const JobTitle = ({ text, record }: { text: string; record: Job }) => {
  const { jobCodeLookup } = useJobTypes()
  return (
    <AssetLink
      basePath={routes.JOBS}
      id={record.uuid}
      schemeId={record.details?.scheme?.id}
    >
      {jobCodeLookup?.[text]}
    </AssetLink>
  )
}

export const renderAssetCategory = (assetCategory: AssetCategory) => (
  <AssetIcon assetCategory={assetCategory} />
)

export const renderPriority = (record: Job) => (
  <div>{record.details?.priority === 'true' ? 'High' : 'Low'}</div>
)

export const renderCreatedByFromJustifications = (isJustified: boolean) => {
  const [type, color] = iconByIsAutomated(isJustified)
  return (
    <Box>
      <span>
        <LegacyIcon
          type={type}
          style={{
            fontSize: 15,
            color,
          }}
        />
      </span>
    </Box>
  )
}

const renderLocation = (
  jobLocationString: string,
  record: Job,
  clientLocation: { longitude: number; latitude: number },
) => {
  const vehicleLocation = record.vehicle?.detail?.module?.location
  const vehicleLocationString = vehicleLocation
    ? `${round(vehicleLocation.latitude, 6)},${round(
        vehicleLocation.longitude,
        6,
      )}`
    : ''
  const location = jobLocationString || vehicleLocationString

  return (
    <a
      href={`https://www.google.com/maps/dir/?api=1&origin=${clientLocation?.latitude},${clientLocation?.longitude}&destination=${location}&travelmode=driving`}
      target="_blank"
      rel="noopener noreferrer"
    >
      {location.split(',').join('\n')}
    </a>
  )
}

interface IColumns {
  nodes: JobListQuery_table_JobConnection_nodes_Job[]
  scheme?: Scheme | null
  clientLocation: { longitude: number; latitude: number }
  vehicleType: VehicleType
  refetch?: RefetchFunction<JobListQuery, JobListQueryVariables>
}

export default function columns(props: IColumns): ColumnsType<Job> {
  return (
    [
      {
        dataIndex: ['details', 'isJustified'],
        key: 'isJustified',
        align: 'center',
        render: renderCreatedByFromJustifications,
      },
      {
        title: 'Id',
        dataIndex: 'uuid',
        key: 'id',
        render: (uuid: string, { details }: Job) => (
          <AssetLink
            basePath={routes.JOBS}
            id={uuid}
            schemeId={details?.scheme?.id}
          >
            {uuid.substring(32)}
          </AssetLink>
        ),
      },
      {
        title: 'Description',
        dataIndex: ['details', 'taskId'],
        key: 'description',
        render: (text, record) => <JobTitle text={text} record={record} />,
      },
      {
        title: 'Scheme',
        dataIndex: ['details', 'scheme', 'name'],
        key: 'scheme_name',
        hidden: props?.scheme?.id,
      },
      {
        title: 'Asset Category',
        dataIndex: ['details', 'assetCategory'],
        key: 'assetCategory',
        render: renderAssetCategory,
      },
      {
        title: 'Asset Name',
        dataIndex: ['details', 'assetName'],
        key: 'assetName',
      },
      {
        title: 'Location',
        dataIndex: ['details', 'location'],
        key: 'location',
        render: (text, record) =>
          renderLocation(text, record, props.clientLocation),
      },
      {
        title: 'Assignee',
        dataIndex: ['details', 'assignee', 'email'],
        key: 'assignee',
      },
      {
        title: 'Status',
        dataIndex: ['details', 'status'],
        key: 'status',
      },
      {
        title: 'Priority',
        key: 'priority',
        render: renderPriority,
      },
      {
        title: 'Date Raised',
        key: 'timestamps.created_at',
        dataIndex: ['timestamps', 'created_at'],
        render: t => formatDatetime(t),
      },
      {
        title: 'Date Updated',
        key: 'timestamps.updated_at',
        dataIndex: ['timestamps', 'updated_at'],
        render: t => formatDatetime(t),
      },
      {
        title: 'Date Completed',
        key: 'timestamps.completed_at',
        dataIndex: ['timestamps', 'completed_at'],
        render: t => formatDatetime(t),
      },
      {
        title: 'Operation',
        key: 'operation',
        fixed: 'right',
        render: job => (
          <Dropdown
            trigger={['click']}
            overlay={<Actions job={job} onSuccess={() => props.refetch?.()} />}
          >
            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
            <a className="ant-dropdown-link">...</a>
          </Dropdown>
        ),
      },
    ]
      // TODO - remove this once we're on antd 5+ where the hidden prop is actually supported
      .filter(item => !item.hidden) as ColumnsType<Job>
  )
}
