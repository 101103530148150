import { useOTAFirmwaresQuery } from 'gql/generated/graphql'
import useWrappedQuery from 'hooks/useWrappedQuery'

const useOTAFirmwares = () =>
  useWrappedQuery(
    useOTAFirmwaresQuery({ pollInterval: 0 }),
    data => data?.ota_firmwares,
  )

export default useOTAFirmwares
