import React, { Fragment, ReactNode } from 'react'

import { withScheme } from 'components/Scheme'
import DeletedUserIcon from 'components/User/DeletedUserIcon'
import { truncateUserStr } from 'utils/truncate'
import { SCHEME } from 'types'
import * as routes from 'constants/routes'
import AssetLink from 'components/Scheme/AssetLink'

type User = {
  id: string
  email?: string | null
  deleted?: boolean
}

type Props = {
  user: User | null
  fallback?: string | ReactNode
  scheme?: SCHEME
}

const UserLink: React.FC<Props> = ({ user, fallback, scheme, children }) => {
  if (!user) {
    return <Fragment>{fallback}</Fragment>
  }

  return (
    <AssetLink
      id={user.id}
      basePath={routes.USERS}
      elementId={`${user.id}-user-link`}
      schemeId={scheme?.id}
    >
      {children || (
        <span>
          {truncateUserStr(user.email || user.id)}
          {user.deleted && <DeletedUserIcon />}
        </span>
      )}
    </AssetLink>
  )
}

export default withScheme(UserLink)
